#product-container {
    .product-container {
        @include mt-s;
            @include breakpoint (medium down) {
                @include mt-0;
            }
        &.product-page {
            border: none;
    
            .product-tags {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: $xs;
    
                .product-tag {
                    @include body-3;
                    color: $grey-3;
                    padding: $xxxs $xxs;
                    border: 1px solid $grey-1;
    
                    &:not(:last-child) {
                        margin-right: $xxs;
                    }
                }
            }
    
            .delivery {
                border: 1px solid $line-color;
            }
    
            .product-description-dropdown {
                border-top: 1px solid $line-color;
        
                .dropdown-toggle {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @include pv-s;
        
                    .dropdown-label {
                        @include body-1;
                        @include semibold;
                    }
                }
    
                .dropdown-content {
                    padding-bottom: $xs;
                }
            }
            .percentage-discount {
                position: relative;
                @include mt-xs;
            }
        }
    }
}

.percentage-discount {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient($first-color, $second-color);
    color: $white;
    padding: calc($xxs / 2) $xxs;
    @include bold;
    z-index: 10;
    span {
        color: $white;
        @include bold;
    }
}
