// DESKTOP
.regular {
	font-weight: 400;
}
.semibold {
	font-weight: 600;
}
.bold {
	font-weight: 700;
}

// TABLET
.regular-tablet {
	@include breakpoint (medium down) {
		font-weight: 400;
	}
}
.semibold-tablet {
	@include breakpoint (medium down) {
		font-weight: 600;
	}
}
.bold-tablet {
	@include breakpoint (medium down) {
		font-weight: 700;
	}
}

// MOBILE
.regular-mobile {
	@include breakpoint (small down) {
		font-weight: 400;
	}
}
.semibold-mobile {
	@include breakpoint (small down) {
		font-weight: 600;
	}
}
.bold-mobile {
	@include breakpoint (small down) {
		font-weight: 700;
	}
}