#home-container {

    &:not(.logged) .under-menu-banner {
        max-height: calc(100vh - 72px - 40px - 32px - 80px);

        @include breakpoint (medium only) {
            max-height: calc(100vh - 74px - 40px - 32px - 80px - 57px);
        }

        @media screen and #{breakpoint(medium down)} and #{breakpoint(landscape)} {
            max-height: none;
        }

        @include breakpoint (small down) {
            max-height: 100vh;
        }
    }
    
    &.logged .under-menu-banner {
        max-height: calc(100vh - 72px - 40px - 32px - 80px);

        @include breakpoint (medium only) {
            max-height: calc(100vh - 74px - 40px - 32px - 80px - 57px);
        }

        @media screen and #{breakpoint(medium down)} and #{breakpoint(landscape)} {
            max-height: none;
        }

        @include breakpoint (small down) {
            max-height: 100vh;
        }
       
    }

    .under-menu-banner {
        @include breakpoint (medium down) {
            overflow-y: auto;
        }
    }


    #brands-swiper {
        min-height: 80px;
        display: flex;
        align-items: center;
    }

    #homepage-search {
        .search-container {
            height: 80px;
            width: 100%;
            
            @include breakpoint (small down) {
                height: 60px;
            }

            [type=search] {
                @include h3;
                color: $black;
                height: 100%;
                background-color: $light-grey;
                padding: $xs $xxll $xs $m;

                @include breakpoint (small down) {
                    font-size: 16px;
                    line-height: 24px;
                    padding: $xs $xxxl $xs $m;
                }
    
                &::placeholder {
                    color: $grey-3;
                    @include h3;

                    @include breakpoint (small down) {
                        font-size: 16px;
                        line-height: 24px;
                        padding: $xs $xxxl $xs $m;
                    }
                }
    
                ~ .search-btn {
                    top: 19px;
                    right: 40px;

                    @include breakpoint (small down) {
                        width: 20px;
                        top: 20px;
                        right: 20px;
                    }
                }
            }
        }
    }

    .z-index-1 {
        z-index: 1;
        position: relative;
    }


    .max-width-xxxl {
        min-width:$xxxl;
    }

    .min-width-m {
        min-width:$m;
    }

    #category-swipers {
        .selectors {
            @include breakpoint (medium down) {
                max-width: 100%;
                overflow-x: auto;
                padding-left: $l;
            }
        }

        [data-tab] {
            position: relative;

            &.selected::after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: -20px;
                width: calc(100% + 40px);
                border-bottom: 3px solid $first-color;

                @include breakpoint (medium down) {
                    left: -8px;
                    width: calc(100% + 16px);
                }
            }
        }
    }

    #deals-arrivals-tabs {
        [data-tab] {
            border: 1px solid $black;

            &.selected {
                background-color: $black;
                
                &, * {
                    color: $white;
                }
            }
        }

        table {
            thead, tbody, tr, th, td {
                background-color: transparent;
                border: none;
            }

            thead th {
                @include semibold;
                @include body-2;
            }

            thead th:not(:first-of-type) {
                text-align: center;
            }

            tbody tr:not(:last-of-type) {
                border-bottom: 1px solid $line-color;
                height: $xll;
            }

            tbody tr td:nth-child(n + 3) {
                text-align: center;
            }


            tbody tr td[data-toggle] {
                .icon {
                    transition: transform .2s;
                }

                &.open .icon {
                    transform: rotate3d(1, 0, 0, 180deg);
                }
            }

            @include breakpoint (medium down) {
                tbody tr td .dropdown {
                    width: 100%;
                }
    
                thead tr th, tbody tr td {
                    max-width: 50vw;

                    > * {
                        display: inline-block;
                        line-height: 24px;
                        max-width: 100%;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    &.profit {
                        background-color: $light-grey;
                        .dropdown {
                            div {
                                background-color: $light-grey;
                            }
                        }
                    }
                }
            }

            [data-table-sort] {
                cursor: pointer;

                .icons {
                    display: inline-block;
                    position: relative;
                    top: -1px;
                    width: 12px;

                    .asc, .desc {
                        display: none;
                    }
                }

                &.sort-asc .asc {
                    display: block;
                }
                &.sort-desc .desc {
                    display: block;
                }
            }
        }
    }

    #tabbed-gender-swipers {
        [data-tab].selected {
            color: $first-color;
        }

        .sep {
            position: relative;
            top: $xxxs;
            border-right: 3px solid $line-color;
            height: 24px;
            transform: rotate(-30deg);
        }
    }

    #subscription-expiring {
        .content {
            position: relative;
            min-width: 28vw;
            padding: $xl;

            .title, .description {
                @include txt-center;
            }

            .description {
                @include mt-s;

                a {
                    color: $second-color;
                    @include semibold;
                    @include underline;
                }
            }

            .close {
                cursor: pointer;
                position: absolute;
                top: $xs;
                right: $xs;
            }
        }
    }
}