.price-subscription {
  display: flex;
  -ms-flex-pack: center;
  align-items: center;
  justify-content: center;
}

.subscription-price-column {
  @include breakpoint(small down) {
    background-color: $second-color;
  }
}

.subscription-total-price {
  min-height: 427px;
  @include breakpoint(medium only) {
    min-height: 613px;
  }
}

.subscriptions-price {
  align-items: center;
}

.change-sub {
  align-items: center;
  margin-top: 30px;
}

.subscription-description {
  min-height: 427px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include breakpoint(medium only) {
    min-height: 454px;
  }
}

.subscriptions-container {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid $line-color;
}

.drop-year-discount-box {
  background: $second-color;
  @include bold;
  @include mv-xxs;
  @include padding-xxxs;
  margin-left: auto;
  margin-right: auto;
  text-align: center; 
  color: $white;
  max-width: 400px;
}