// *** DO NOT REMOVE THIS ***
//$$ variables-hook-start $$

// colors
$black: #282828;
$grey-3: #4c4c4c;
$grey-2: #BDBDBD;
$grey-1: #E0E0E0;
$light-grey: #F7F7F7;
$white: #FFFFFF;
//$first-color: #FF1D58;
$first-color: #EA3C5C;
$second-color: #0049B7;
$third-color: #219653;
$fourth-color:#F2EBEB;
$line-color: $grey-1;
$danger: #F91E43;
$success: #41D682;

// borders
$radius-small: 4px;
$radius-medium: 8px;
$radius-large: 16px;

//$$ variables-hook-end $$
// *** DO NOT REMOVE THIS ***

$box-shadow: rgba(0, 73, 183, 0.06);
$box-shadow-hover: rgba(0, 73, 183, 0.15);

@function hover-color($color) {
    @return lighten($color, 10%);
}

// for third party elements
$grey-paypal: #EEE;

// share buttons
$blue-facebook: #1877F2;
$blue-google: #4285F4;
$green-whatsapp: #3FC351;

// z-indexes
$messagebox-z: 3000;
$overlay-z: 2000;
$header-z: 1000;
$menu-z: 1000;
$under-menu-banner-z: 100;

// fonts
$first-font: 'Mulish', sans-serif;
$second-font: 'Playfair Display', sans-serif;


// Inputs

/** CHECKBOX **/

// Default appearance of the checked checkbox, 'full' (█) or 'tick' (✓)
$checkbox-default-mode: 'full';

// Checkbox width & height
$checkbox-size: $xs;

// Distance between the checkbox and the label
$checkbox-offset-x: $xxs;

// Vertical offset of the checkbox
$checkbox-offset-y: 4px;

// Color of the checkbox's borders
$checkbox-border-color: $grey-3;

// Width of the checkbox's borders
$checkbox-border-width: 1px;

// Border radius of the checkbox (also that of full checkbox)
$checkbox-border-radius: 0;

// Space between checkbox filling and checkbox borders in 'full' mode
$checkbox-full-margin: 1px;

// Width of the checkbox's tick mark in 'tick' mode
$checkbox-tick-width: 2px;

// Length checkbox's tick mark left side in 'tick' mode
$checkbox-tick-limb-left-length: $checkbox-size - 4px;

// Length checkbox's tick mark right side in 'tick' mode
$checkbox-tick-limb-right-length: $checkbox-size + 2px;

// Horizontal offset of the checkbox's tick mark in 'tick' mode
$checkbox-tick-offset-x: 0;

// Vertical offset of the checkbox's tick mark in 'tick' mode
$checkbox-tick-offset-y: 2px;

// Color of the checkbox's filling in 'full' mode, or that of the checkbox's tick mark in 'tick' mode
$checkbox-checked-color: $first-color;

/** END CHECKBOX **/

/** RADIO **/

// Radio button width & height
$radio-size: $xs;

// Radio button border width
$radio-border-width: 1px;

// Radio button border color
$radio-border-color: $grey-3;

// Distance between the radio button and the label
$radio-offset-x: $xxs;

// Verical offset of the radio button
$radio-offset-y: 5px;

// Distance between the radio button border and the radio button filling
$radio-selected-margin: 3px;

// Color of the radio button filling
$radio-selected-color: $black;

/** END RADIO **/