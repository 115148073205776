#subscribe-container {

    .multi-step-container {
        .step:not(.active) {
            display: none;
        }

        .btn-container:not(.active) {
            display: none;
        }

        .step-bullet {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            &:not(:last-child) {
                @include mr-s;
            }

            p {
                color: $grey-3;
                @include mt-xxs;
                @include regular;
                @include body-2;
            }

            &::before {
                content: '';
                width: 24px;
                height: 24px;
                border: 2px solid transparent;
                border-radius: 50%;
            }

            &::after {
                content: '';
                position: absolute;
                top: 3px;
                width: 18px;
                height: 18px;
                background-color: $light-grey;
                border-radius: 50%;
            }

            &.active p {
                color: $black;
                @include semibold;
            }

            &.active::before {
                border-color: $first-color;
            }

            &.active::after {
                background-color: $first-color;
            }

            .connector {
                position: absolute;
                top: 12px;
                right: 30px;
                width: 46px;
                border-bottom: 2px solid $light-grey;
            }

            &.active .connector {
                width: 43px;
                right: 33px;
                border-bottom-color: $first-color;
            }
        }

        #business-type {
            @include breakpoint (medium down) {
                flex-wrap: wrap;
            }

            @include breakpoint (small down) {
                .radio-container {
                    min-width: 50%;
                }
            }
        }
    }

}