.cms-wrapper {
    @include mb-xxl;

    p {
        @include regular;
        @include mb-xxs;
    }

    ul,
    ol {
        padding: 0 0 16px 24px;
    }

    .authenticity-banner-image {
        padding-top:calc($xxxll*2);
        padding-bottom:calc($xxxll*2);
        background-size: cover;
    }
}

.top-banner {
    height: 40px;

    @include breakpoint (small down) {
        height: 32px
    }

    .top-banner-text {
        @include bold;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.5em;
    
        @include breakpoint (small down) {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.23em;
        }
    }
}

.under-menu-banner {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;

    @include breakpoint (medium down) {
        background-position-x: 50%;
        @include pv-l;
    }

    .image {
        width: 100%;
        visibility: hidden;
    }

    .banner-columns {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include breakpoint (medium down) {
            position: relative;
        }
    }
}

.netcomm-awards-container {
    display: flex;
    justify-content: flex-start;
}

#connect-with-fashion {
    .foreground-image {
        position: relative;
        width: 50%;
        z-index: 1;
    }

    .background-image {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%;
        z-index: 0;
    }

    .btn {
        position: relative;
        left: -25%;

        @include breakpoint (medium down) {
            left: 0;
        }
    }
}

.half-width-banner, .full-width-banner {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;

    @include breakpoint (medium down) {
        min-height: 300px;
    }

    > img {
        width: 100%;
        visibility: hidden;

        @include breakpoint (medium down) {
            display: none;
        }
    }

    .vertical-text {
        position: absolute;
        right: 5%;
        bottom: 10%;
        font-size: 100px;
        line-height: 133px;
        writing-mode: vertical-rl;
        transform: rotate(180deg);

        @include breakpoint (medium down) {
            position: relative;
            bottom: -15%;
            right: -60%;
        }
    }

    .button-box-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include breakpoint (medium down) {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .button-box {
            margin: auto;
            width: min-content;
        }
    }

    .title-button-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include breakpoint (medium down) {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .title-button {
            margin: auto;
            margin-left: $xxxl;
            width: min-content;
            max-width: 80%;

            @include breakpoint (medium down) {
                margin-left: $xs;

                .title {
                    font-size: 50px;
                    line-height: 72px;
                }
            }
        }
    }
}

.half-width-banner {
    background-position-x: 100%;
}

.full-width-banner {
    .elements {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    @include breakpoint (medium down) {
        background-position-x: 25%;
    }
}

.half-width-banner-container, .full-width-banner {
    @include breakpoint (medium down) {
        &:not(:first-child) {
            margin-top: $s;
        }
    }
}

.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include pv-xxxll;

    .error-404 {
        @include h1;
    }

    .page-not-found {
        @include h4;
        @include mb-l;
    }
}

.main-title {
    @include h2;
    @include breakpoint (medium down) {
        @include h3
    }
}

.faq-section {
    .title {
        margin-bottom: $s;
    }

    .faq {
            &:nth-child(odd) {
                background: $light-grey;
            }
            
            &:nth-child(even) {
                background: $white
            }
        .toggle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include padding-s;
            cursor: pointer;
        }

        .toggle {
            .open {
                display: block;
            }
            .close {
                display: none;
            }
        }

        .toggle.open {
            .open {
                display: none;
            }
            .close {
                display: block;
            }
        }

        .answer {
            display: none;
            @include pv-xs;
            @include ph-s;
        }
    }
}

.contact-form-box {
    background-color: $light-grey;
    padding: $s;

    .title {
        margin-bottom: $xxs;
    }
}

.authenticity-circle-container {
    position: relative;
    width: 300px;
    height: 300px;
    padding: $xxl;
    border: 3px solid $white;
    border-radius: 50%;
    box-shadow: 0 0 14px 0 $grey-3;

    &.first {
        background-color: $first-color;
    }

    &.second {
        background-color: darken($color: $first-color, $amount: 20%);
    }

    &.third {
        background-color: darken($color: $first-color, $amount: 40%);
    }

    .text {
        color: $white;
        @include txt-center;
    }
}

.cms-wrapper {
    .item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        &:not(:first-child) {
            margin-top: $s;
        }

        .index {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: $m;
            min-width: $xl;
            min-height: $xl;
            font-size: 18px;
            background-color: $white;
            border: 1px solid $first-color;
            border-radius: 50%;
        }
    }
}

.category-banner-text {
    p {
        @include body-2;
    }
}


.banner-2 {
    background-color: $light-grey;
    border: 1px solid $grey-2;
    overflow: hidden;

    .columns {
        z-index: 1;
    }

    &::before {
        content: "";
        position: absolute;
        width: 361px;
        height: 361px;
        background-color: $first-color;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        left: -219px;

        @include breakpoint(medium down) {
            display: none;
        }
    }

    &::after {
        content: "";
        position: absolute;
        width: 657px;
        height: 657px;
        background-color: $first-color;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        right: -107px;

        @include breakpoint(medium only) {
            right: -56%;
        }

        @include breakpoint(small down) {
            top: initial;
            bottom: -650px;
            right: -370px;
        }
    }
}