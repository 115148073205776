.video-container{
    background-image: linear-gradient(rgba($black, 0.5),rgba($black, 0.5)), var(--cover-img) !important;
    border-radius: $s;

    height: 100%;

    
    @include breakpoint (medium down) {
        height: calc((100vw - 2 / 16 * 100vw) * 9 / 16);
    }

    iframe{
        border-radius: $s;
        width: 100%;
        height: 100%;
        border:none;
    } 
}

.shopify-video{
    .video-container{
        height: calc((70vw - 2 / 16 * 70vw) * 9 / 16);

        @include breakpoint (medium down) {
            height: calc((100vw - 2 / 16 * 100vw) * 9 / 16);
        }
    }
}