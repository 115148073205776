@import "node_modules/foundation-sites/scss/foundation";

$breakpoints: (
  small: 0px,
  medium: 640px + 1px,
  large: 1024px + 1px,
  xlarge: 1200px + 1px,
  xxlarge: 1440px + 1px,
);

$flex: true;

@if $flex {
    $global-flexbox: true !global;
}

@include foundation-global-styles;
@if not $flex {
    @include foundation-grid;
} @else {
    @include foundation-flex-grid;
}
//@include foundation-typography;
@include foundation-forms;
//@include foundation-button;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;

@if $flex {
    @include foundation-flex-classes;
}

@import "spacings";
@import "variables";
@import "flex";
@import "mixins";
@import "general";
@import "inputs";
// *** DO NOT REMOVE THIS ***
//$$ imports-hook-start $$
@import "style/typography";
@import "style/colors";
@import "style/backgrounds";
@import "style/borders";
@import "style/text-alignment";
@import "style/text-decorations";
@import "style/text-transforms";
@import "style/font-weights";
@import "style/fonts";
//$$ imports-hook-end $$
// *** DO NOT REMOVE THIS ***
@import "components/breadcrumb";
@import "components/dialog";
@import "components/grid-table";
@import "components/cart-summary";
@import "components/errors";
@import "components/footer";
@import "components/header";
@import "components/menu";
@import "components/pagination";
@import "components/product";
@import "components/subscriptions";
@import "components/support";
@import "components/swiper";
@import "components/video";
@import "pages/blog";
@import "pages/cart";
@import "pages/catalog";
@import "pages/checkout";
@import "pages/cms";
@import "pages/designers";
@import "pages/home";
@import "pages/product";
@import "pages/profile";
@import "pages/subscribe";
@import "pages/subscription";
@import "pages/subscriptions";
@import "pages/wishlist";

// Swiper
@import "node_modules/swiper/swiper-bundle";

// Fonts
@import "../fonts/mulish/mulish.css";
@import "../fonts/playfair_display/playfair_display.css";

// intlTelInput
@import "node_modules/intl-tel-input/build/css/intlTelInput";

.iti {
  width: 100%;
  margin: 0 0 1rem;
}

.iti__flag {
  background-image: url("/skins/current-skin/images/flags/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("/skins/current-skin/images/flags/flags@2x.png");
  }
}

.background-white-pink {
  position: relative;

  &::before {
      content: "";
      background-color: $fourth-color;
      position: absolute;
      top: 50%;
      height: 50%;
      width: 100%;
      display: block;
      border-radius: $m;
  }

  @include breakpoint(medium down){
      &::before {            
          top: 35%;
          height: 65%;
      }
  }
}

// PhotoSwipe
@import "node_modules/photoswipe/dist/photoswipe";

h1, .h1 {
  p, span {
    @include h1;
  }
}
h2, .h2 {
  p, span {
    @include h2;
  }
}
h3, .h3 {
  p, span {
    @include h3;
  }
}
h4, .h4 {
  p, span {
    @include h4;
  }
}