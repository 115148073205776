$xxxs: 4px;
$xxs: 8px;
$xs: 16px;
$s: 24px;
$m: 32px;
$l: 40px;
$xl: 48px;
$xxl: 56px;
$xxxl: 64px;
$ll: 72px;
$xll: 80px;
$xxll: 88px;
$xxxll: 96px;

// MARGIN
.margin-auto {
	margin: auto;
}
.margin-0 {
	margin: 0;
}
.margin-xxxs {
	margin: $xxxs;
}
.margin-xxs {
	margin: $xxs;
}
.margin-xs {
	margin: $xs;
}
.margin-s {
	margin: $s;
}
.margin-m {
	margin: $m;
}
.margin-l {
	margin: $l;
}
.margin-xl {
	margin: $xl;
}
.margin-xxl {
	margin: $xxl;
}
.margin-xxxl {
	margin: $xxxl;
}
.margin-ll {
	margin: $ll;
}
.margin-xll {
	margin: $xll;
}
.margin-xxll {
	margin: $xxll;
}
.margin-xxxll {
	margin: $xxxll;
}

.mt-auto {
	margin-top: auto;
}
.mt-0 {
	margin-top: 0;
}
.mt-xxxs {
	margin-top: $xxxs;
}
.mt-xxs {
	margin-top: $xxs;
}
.mt-xs {
	margin-top: $xs;
}
.mt-s {
	margin-top: $s;
}
.mt-m {
	margin-top: $m;
}
.mt-l {
	margin-top: $l;
}
.mt-xl {
	margin-top: $xl;
}
.mt-xxl {
	margin-top: $xxl;
}
.mt-xxxl {
	margin-top: $xxxl;
}
.mt-ll {
	margin-top: $ll;
}
.mt-xll {
	margin-top: $xll;
}
.mt-xxll {
	margin-top: $xxll;
}
.mt-xxxll {
	margin-top: $xxxll;
}

.mb-auto {
	margin-bottom: auto;
}
.mb-0 {
	margin-bottom: 0;
}
.mb-xxxs {
	margin-bottom: $xxxs;
}
.mb-xxs {
	margin-bottom: $xxs;
}
.mb-xs {
	margin-bottom: $xs;
}
.mb-s {
	margin-bottom: $s;
}
.mb-m {
	margin-bottom: $m;
}
.mb-l {
	margin-bottom: $l;
}
.mb-xl {
	margin-bottom: $xl;
}
.mb-xxl {
	margin-bottom: $xxl;
}
.mb-xxxl {
	margin-bottom: $xxxl;
}
.mb-ll {
	margin-bottom: $ll;
}
.mb-xll {
	margin-bottom: $xll;
}
.mb-xxll {
	margin-bottom: $xxll;
}
.mb-xxxll {
	margin-bottom: $xxxll;
}

.ml-auto {
	margin-left: auto;
}
.ml-0 {
	margin-left: 0;
}
.ml-xxxs {
	margin-left: $xxxs;
}
.ml-xxs {
	margin-left: $xxs;
}
.ml-xs {
	margin-left: $xs;
}
.ml-s {
	margin-left: $s;
}
.ml-m {
	margin-left: $m;
}
.ml-l {
	margin-left: $l;
}
.ml-xl {
	margin-left: $xl;
}
.ml-xxl {
	margin-left: $xxl;
}
.ml-xxxl {
	margin-left: $xxxl;
}
.ml-ll {
	margin-left: $ll;
}
.ml-xll {
	margin-left: $xll;
}
.ml-xxll {
	margin-left: $xxll;
}
.ml-xxxll {
	margin-left: $xxxll;
}

.mr-auto {
	margin-right: auto;
}
.mr-0 {
	margin-right: 0;
}
.mr-xxxs {
	margin-right: $xxxs;
}
.mr-xxs {
	margin-right: $xxs;
}
.mr-xs {
	margin-right: $xs;
}
.mr-s {
	margin-right: $s;
}
.mr-m {
	margin-right: $m;
}
.mr-l {
	margin-right: $l;
}
.mr-xl {
	margin-right: $xl;
}
.mr-xxl {
	margin-right: $xxl;
}
.mr-xxxl {
	margin-right: $xxxl;
}
.mr-ll {
	margin-right: $ll;
}
.mr-xll {
	margin-right: $xll;
}
.mr-xxll {
	margin-right: $xxll;
}
.mr-xxxll {
	margin-right: $xxxll;
}

.mh-auto {
	margin-left: auto;
	margin-right: auto;
}
.mh-0 {
	margin-left: 0;
	margin-right: 0;
}
.mh-xxxs {
	margin-left: $xxxs;
	margin-right: $xxxs;
}
.mh-xxs {
	margin-left: $xxs;
	margin-right: $xxs;
}
.mh-xs {
	margin-left: $xs;
	margin-right: $xs;
}
.mh-s {
	margin-left: $s;
	margin-right: $s;
}
.mh-m {
	margin-left: $m;
	margin-right: $m;
}
.mh-l {
	margin-left: $l;
	margin-right: $l;
}
.mh-xl {
	margin-left: $xl;
	margin-right: $xl;
}
.mh-xxl {
	margin-left: $xxl;
	margin-right: $xxl;
}
.mh-xxxl {
	margin-left: $xxxl;
	margin-right: $xxxl;
}
.mh-ll {
	margin-left: $ll;
	margin-right: $ll;
}
.mh-xll {
	margin-left: $xll;
	margin-right: $xll;
}
.mh-xxll {
	margin-left: $xxll;
	margin-right: $xxll;
}
.mh-xxxll {
	margin-left: $xxxll;
	margin-right: $xxxll;
}


.mv-auto {
	margin-top: auto;
	margin-bottom: auto;
}
.mv-0 {
	margin-top: 0;
	margin-bottom: 0;
}
.mv-xxxs {
	margin-top: $xxxs;
	margin-bottom: $xxxs;
}
.mv-xxs {
	margin-top: $xxs;
	margin-bottom: $xxs;
}
.mv-xs {
	margin-top: $xs;
	margin-bottom: $xs;
}
.mv-s {
	margin-top: $s;
	margin-bottom: $s;
}
.mv-m {
	margin-top: $m;
	margin-bottom: $m;
}
.mv-l {
	margin-top: $l;
	margin-bottom: $l;
}
.mv-xl {
	margin-top: $xl;
	margin-bottom: $xl;
}
.mv-xxl {
	margin-top: $xxl;
	margin-bottom: $xxl;
}
.mv-xxxl {
	margin-top: $xxxl;
	margin-bottom: $xxxl;
}
.mv-ll {
	margin-top: $ll;
	margin-bottom: $ll;
}
.mv-xll {
	margin-top: $xll;
	margin-bottom: $xll;
}
.mv-xxll {
	margin-top: $xxll;
	margin-bottom: $xxll;
}
.mv-xxxll {
	margin-top: $xxxll;
	margin-bottom: $xxxll;
}

.margin-0-tablet {
	@include breakpoint (medium down) {
		margin: 0;
	}
}
.margin-xxxs-tablet {
	@include breakpoint (medium down) {
		margin: $xxxs;
	}
}
.margin-xxs-tablet {
	@include breakpoint (medium down) {
		margin: $xxs;
	}
}
.margin-xs-tablet {
	@include breakpoint (medium down) {
		margin: $xs;
	}
}
.margin-s-tablet {
	@include breakpoint (medium down) {
		margin: $s;
	}
}
.margin-m-tablet {
	@include breakpoint (medium down) {
		margin: $m;
	}
}
.margin-l-tablet {
	@include breakpoint (medium down) {
		margin: $l;
	}
}
.margin-xl-tablet {
	@include breakpoint (medium down) {
		margin: $xl;
	}
}
.margin-xxl-tablet {
	@include breakpoint (medium down) {
		margin: $xxl;
	}
}
.margin-xxxl-tablet {
	@include breakpoint (medium down) {
		margin: $xxxl;
	}
}
.margin-ll-tablet {
	@include breakpoint (medium down) {
		margin: $ll;
	}
}
.margin-xll-tablet {
	@include breakpoint (medium down) {
		margin: $xll;
	}
}
.margin-xxll-tablet {
	@include breakpoint (medium down) {
		margin: $xxll;
	}
}
.margin-xxxll-tablet {
	@include breakpoint (medium down) {
		margin: $xxxll;
	}
}

.mt-0-tablet {
	@include breakpoint (medium down) {
		margin-top: 0;
	}
}
.mt-xxxs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxs;
	}
}
.mt-xxs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxs;
	}
}
.mt-xs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xs;
	}
}
.mt-s-tablet {
	@include breakpoint (medium down) {
		margin-top: $s;
	}
}
.mt-m-tablet {
	@include breakpoint (medium down) {
		margin-top: $m;
	}
}
.mt-l-tablet {
	@include breakpoint (medium down) {
		margin-top: $l;
	}
}
.mt-xl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xl;
	}
}
.mt-xxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxl;
	}
}
.mt-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxl;
	}
}
.mt-ll-tablet {
	@include breakpoint (medium down) {
		margin-top: $ll;
	}
}
.mt-xll-tablet {
	@include breakpoint (medium down) {
		margin-top: $xll;
	}
}
.mt-xxll-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxll;
	}
}
.mt-xxxll-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxll;
	}
}

.mb-0-tablet {
	@include breakpoint (medium down) {
		margin-bottom: 0;
	}
}
.mb-xxxs-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxxs;
	}
}
.mb-xxs-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxs;
	}
}
.mb-xs-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xs;
	}
}
.mb-s-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $s;
	}
}
.mb-m-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $m;
	}
}
.mb-l-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $l;
	}
}
.mb-xl-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xl;
	}
}
.mb-xxl-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxl;
	}
}
.mb-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxxl;
	}
}
.mb-ll-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $ll;
	}
}
.mb-xll-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xll;
	}
}
.mb-xxll-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxll;
	}
}
.mb-xxxll-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxxll;
	}
}

.ml-0-tablet {
	@include breakpoint (medium down) {
		margin-left: 0;
	}
}
.ml-xxxs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxs;
	}
}
.ml-xxs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxs;
	}
}
.ml-xs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xs;
	}
}
.ml-s-tablet {
	@include breakpoint (medium down) {
		margin-left: $s;
	}
}
.ml-m-tablet {
	@include breakpoint (medium down) {
		margin-left: $m;
	}
}
.ml-l-tablet {
	@include breakpoint (medium down) {
		margin-left: $l;
	}
}
.ml-xl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xl;
	}
}
.ml-xxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxl;
	}
}
.ml-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxl;
	}
}
.ml-ll-tablet {
	@include breakpoint (medium down) {
		margin-left: $ll;
	}
}
.ml-xll-tablet {
	@include breakpoint (medium down) {
		margin-left: $xll;
	}
}
.ml-xxll-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxll;
	}
}
.ml-xxxll-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxll;
	}
}

.mr-0-tablet {
	@include breakpoint (medium down) {
		margin-right: 0;
	}
}
.mr-xxxs-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxxs;
	}
}
.mr-xxs-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxs;
	}
}
.mr-xs-tablet {
	@include breakpoint (medium down) {
		margin-right: $xs;
	}
}
.mr-s-tablet {
	@include breakpoint (medium down) {
		margin-right: $s;
	}
}
.mr-m-tablet {
	@include breakpoint (medium down) {
		margin-right: $m;
	}
}
.mr-l-tablet {
	@include breakpoint (medium down) {
		margin-right: $l;
	}
}
.mr-xl-tablet {
	@include breakpoint (medium down) {
		margin-right: $xl;
	}
}
.mr-xxl-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxl;
	}
}
.mr-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxxl;
	}
}
.mr-ll-tablet {
	@include breakpoint (medium down) {
		margin-right: $ll;
	}
}
.mr-xll-tablet {
	@include breakpoint (medium down) {
		margin-right: $xll;
	}
}
.mr-xxll-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxll;
	}
}
.mr-xxxll-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxxll;
	}
}

.mh-0-tablet {
	@include breakpoint (medium down) {
		margin-left: 0;
		margin-right: 0;
	}
}
.mh-xxxs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxs;
		margin-right: $xxxs;
	}
}
.mh-xxs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxs;
		margin-right: $xxs;
	}
}
.mh-xs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xs;
		margin-right: $xs;
	}
}
.mh-s-tablet {
	@include breakpoint (medium down) {
		margin-left: $s;
		margin-right: $s;
	}
}
.mh-m-tablet {
	@include breakpoint (medium down) {
		margin-left: $m;
		margin-right: $m;
	}
}
.mh-l-tablet {
	@include breakpoint (medium down) {
		margin-left: $l;
		margin-right: $l;
	}
}
.mh-xl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xl;
		margin-right: $xl;
	}
}
.mh-xxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxl;
		margin-right: $xxl;
	}
}
.mh-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxl;
		margin-right: $xxxl;
	}
}
.mh-ll-tablet {
	@include breakpoint (medium down) {
		margin-left: $ll;
		margin-right: $ll;
	}
}
.mh-xll-tablet {
	@include breakpoint (medium down) {
		margin-left: $xll;
		margin-right: $xll;
	}
}
.mh-xxll-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxll;
		margin-right: $xxll;
	}
}
.mh-xxxll-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxll;
		margin-right: $xxxll;
	}
}

.mv-0-tablet {
	@include breakpoint (medium down) {
		margin-top: 0;
		margin-bottom: 0;
	}
}
.mv-xxxs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxs;
		margin-bottom: $xxxs;
	}
}
.mv-xxs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxs;
		margin-bottom: $xxs;
	}
}
.mv-xs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xs;
		margin-bottom: $xs;
	}
}
.mv-s-tablet {
	@include breakpoint (medium down) {
		margin-top: $s;
		margin-bottom: $s;
	}
}
.mv-m-tablet {
	@include breakpoint (medium down) {
		margin-top: $m;
		margin-bottom: $m;
	}
}
.mv-l-tablet {
	@include breakpoint (medium down) {
		margin-top: $l;
		margin-bottom: $l;
	}
}
.mv-xl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xl;
		margin-bottom: $xl;
	}
}
.mv-xxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxl;
		margin-bottom: $xxl;
	}
}
.mv-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxl;
		margin-bottom: $xxxl;
	}
}
.mv-ll-tablet {
	@include breakpoint (medium down) {
		margin-top: $ll;
		margin-bottom: $ll;
	}
}
.mv-xll-tablet {
	@include breakpoint (medium down) {
		margin-top: $xll;
		margin-bottom: $xll;
	}
}
.mv-xxll-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxll;
		margin-bottom: $xxll;
	}
}
.mv-xxxll-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxll;
		margin-bottom: $xxxll;
	}
}

.margin-0-mobile {
	@include breakpoint (small down) {
		margin: 0;
	}
}
.margin-xxxs-mobile {
	@include breakpoint (small down) {
		margin: $xxxs;
	}
}
.margin-xxs-mobile {
	@include breakpoint (small down) {
		margin: $xxs;
	}
}
.margin-xs-mobile {
	@include breakpoint (small down) {
		margin: $xs;
	}
}
.margin-s-mobile {
	@include breakpoint (small down) {
		margin: $s;
	}
}
.margin-m-mobile {
	@include breakpoint (small down) {
		margin: $m;
	}
}
.margin-l-mobile {
	@include breakpoint (small down) {
		margin: $l;
	}
}
.margin-xl-mobile {
	@include breakpoint (small down) {
		margin: $xl;
	}
}
.margin-xxl-mobile {
	@include breakpoint (small down) {
		margin: $xxl;
	}
}
.margin-xxxl-mobile {
	@include breakpoint (small down) {
		margin: $xxxl;
	}
}
.margin-ll-mobile {
	@include breakpoint (small down) {
		margin: $ll;
	}
}
.margin-xll-mobile {
	@include breakpoint (small down) {
		margin: $xll;
	}
}
.margin-xxll-mobile {
	@include breakpoint (small down) {
		margin: $xxll;
	}
}
.margin-xxxll-mobile {
	@include breakpoint (small down) {
		margin: $xxxll;
	}
}

.mt-0-mobile {
	@include breakpoint (small down) {
		margin-top: 0;
	}
}
.mt-xxxs-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxs;
	}
}
.mt-xxs-mobile {
	@include breakpoint (small down) {
		margin-top: $xxs;
	}
}
.mt-xs-mobile {
	@include breakpoint (small down) {
		margin-top: $xs;
	}
}
.mt-s-mobile {
	@include breakpoint (small down) {
		margin-top: $s;
	}
}
.mt-m-mobile {
	@include breakpoint (small down) {
		margin-top: $m;
	}
}
.mt-l-mobile {
	@include breakpoint (small down) {
		margin-top: $l;
	}
}
.mt-xl-mobile {
	@include breakpoint (small down) {
		margin-top: $xl;
	}
}
.mt-xxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxl;
	}
}
.mt-xxxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxl;
	}
}
.mt-ll-mobile {
	@include breakpoint (small down) {
		margin-top: $ll;
	}
}
.mt-xll-mobile {
	@include breakpoint (small down) {
		margin-top: $xll;
	}
}
.mt-xxll-mobile {
	@include breakpoint (small down) {
		margin-top: $xxll;
	}
}
.mt-xxxll-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxll;
	}
}

.mb-0-mobile {
	@include breakpoint (small down) {
		margin-bottom: 0;
	}
}
.mb-xxxs-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxxs;
	}
}
.mb-xxs-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxs;
	}
}
.mb-xs-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xs;
	}
}
.mb-s-mobile {
	@include breakpoint (small down) {
		margin-bottom: $s;
	}
}
.mb-m-mobile {
	@include breakpoint (small down) {
		margin-bottom: $m;
	}
}
.mb-l-mobile {
	@include breakpoint (small down) {
		margin-bottom: $l;
	}
}
.mb-xl-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xl;
	}
}
.mb-xxl-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxl;
	}
}
.mb-xxxl-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxxl;
	}
}
.mb-ll-mobile {
	@include breakpoint (small down) {
		margin-bottom: $ll;
	}
}
.mb-xll-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xll;
	}
}
.mb-xxll-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxll;
	}
}
.mb-xxxll-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxxll;
	}
}

.ml-0-mobile {
	@include breakpoint (small down) {
		margin-left: 0;
	}
}
.ml-xxxs-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxs;
	}
}
.ml-xxs-mobile {
	@include breakpoint (small down) {
		margin-left: $xxs;
	}
}
.ml-xs-mobile {
	@include breakpoint (small down) {
		margin-left: $xs;
	}
}
.ml-s-mobile {
	@include breakpoint (small down) {
		margin-left: $s;
	}
}
.ml-m-mobile {
	@include breakpoint (small down) {
		margin-left: $m;
	}
}
.ml-l-mobile {
	@include breakpoint (small down) {
		margin-left: $l;
	}
}
.ml-xl-mobile {
	@include breakpoint (small down) {
		margin-left: $xl;
	}
}
.ml-xxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxl;
	}
}
.ml-xxxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxl;
	}
}
.ml-ll-mobile {
	@include breakpoint (small down) {
		margin-left: $ll;
	}
}
.ml-xll-mobile {
	@include breakpoint (small down) {
		margin-left: $xll;
	}
}
.ml-xxll-mobile {
	@include breakpoint (small down) {
		margin-left: $xxll;
	}
}
.ml-xxxll-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxll;
	}
}

.mr-0-mobile {
	@include breakpoint (small down) {
		margin-right: 0;
	}
}
.mr-xxxs-mobile {
	@include breakpoint (small down) {
		margin-right: $xxxs;
	}
}
.mr-xxs-mobile {
	@include breakpoint (small down) {
		margin-right: $xxs;
	}
}
.mr-xs-mobile {
	@include breakpoint (small down) {
		margin-right: $xs;
	}
}
.mr-s-mobile {
	@include breakpoint (small down) {
		margin-right: $s;
	}
}
.mr-m-mobile {
	@include breakpoint (small down) {
		margin-right: $m;
	}
}
.mr-l-mobile {
	@include breakpoint (small down) {
		margin-right: $l;
	}
}
.mr-xl-mobile {
	@include breakpoint (small down) {
		margin-right: $xl;
	}
}
.mr-xxl-mobile {
	@include breakpoint (small down) {
		margin-right: $xxl;
	}
}
.mr-xxxl-mobile {
	@include breakpoint (small down) {
		margin-right: $xxxl;
	}
}
.mr-ll-mobile {
	@include breakpoint (small down) {
		margin-right: $ll;
	}
}
.mr-xll-mobile {
	@include breakpoint (small down) {
		margin-right: $xll;
	}
}
.mr-xxll-mobile {
	@include breakpoint (small down) {
		margin-right: $xxll;
	}
}
.mr-xxxll-mobile {
	@include breakpoint (small down) {
		margin-right: $xxxll;
	}
}

.mh-0-mobile {
	@include breakpoint (small down) {
		margin-left: 0;
		margin-right: 0;
	}
}
.mh-xxxs-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxs;
		margin-right: $xxxs;
	}
}
.mh-xxs-mobile {
	@include breakpoint (small down) {
		margin-left: $xxs;
		margin-right: $xxs;
	}
}
.mh-xs-mobile {
	@include breakpoint (small down) {
		margin-left: $xs;
		margin-right: $xs;
	}
}
.mh-s-mobile {
	@include breakpoint (small down) {
		margin-left: $s;
		margin-right: $s;
	}
}
.mh-m-mobile {
	@include breakpoint (small down) {
		margin-left: $m;
		margin-right: $m;
	}
}
.mh-l-mobile {
	@include breakpoint (small down) {
		margin-left: $l;
		margin-right: $l;
	}
}
.mh-xl-mobile {
	@include breakpoint (small down) {
		margin-left: $xl;
		margin-right: $xl;
	}
}
.mh-xxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxl;
		margin-right: $xxl;
	}
}
.mh-xxxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxl;
		margin-right: $xxxl;
	}
}
.mh-ll-mobile {
	@include breakpoint (small down) {
		margin-left: $ll;
		margin-right: $ll;
	}
}
.mh-xll-mobile {
	@include breakpoint (small down) {
		margin-left: $xll;
		margin-right: $xll;
	}
}
.mh-xxll-mobile {
	@include breakpoint (small down) {
		margin-left: $xxll;
		margin-right: $xxll;
	}
}
.mh-xxxll-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxll;
		margin-right: $xxxll;
	}
}

.mv-0-mobile {
	@include breakpoint (small down) {
		margin-top: 0;
		margin-bottom: 0;
	}
}
.mv-xxxs-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxs;
		margin-bottom: $xxxs;
	}
}
.mv-xxs-mobile {
	@include breakpoint (small down) {
		margin-top: $xxs;
		margin-bottom: $xxs;
	}
}
.mv-xs-mobile {
	@include breakpoint (small down) {
		margin-top: $xs;
		margin-bottom: $xs;
	}
}
.mv-s-mobile {
	@include breakpoint (small down) {
		margin-top: $s;
		margin-bottom: $s;
	}
}
.mv-m-mobile {
	@include breakpoint (small down) {
		margin-top: $m;
		margin-bottom: $m;
	}
}
.mv-l-mobile {
	@include breakpoint (small down) {
		margin-top: $l;
		margin-bottom: $l;
	}
}
.mv-xl-mobile {
	@include breakpoint (small down) {
		margin-top: $xl;
		margin-bottom: $xl;
	}
}
.mv-xxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxl;
		margin-bottom: $xxl;
	}
}
.mv-xxxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxl;
		margin-bottom: $xxxl;
	}
}
.mv-ll-mobile {
	@include breakpoint (small down) {
		margin-top: $ll;
		margin-bottom: $ll;
	}
}
.mv-xll-mobile {
	@include breakpoint (small down) {
		margin-top: $xll;
		margin-bottom: $xll;
	}
}
.mv-xxll-mobile {
	@include breakpoint (small down) {
		margin-top: $xxll;
		margin-bottom: $xxll;
	}
}
.mv-xxxll-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxll;
		margin-bottom: $xxxll;
	}
}


// PADDING
.padding-0 {
	padding: 0;
}
.padding-xxxs {
	padding: $xxxs;
}
.padding-xxs {
	padding: $xxs;
}
.padding-xs {
	padding: $xs;
}
.padding-s {
	padding: $s;
}
.padding-m {
	padding: $m;
}
.padding-l {
	padding: $l;
}
.padding-xl {
	padding: $xl;
}
.padding-xxl {
	padding: $xxl;
}
.padding-xxxl {
	padding: $xxxl;
}
.padding-ll {
	padding: $ll;
}
.padding-xll {
	padding: $xll;
}
.padding-xxll {
	padding: $xxll;
}
.padding-xxxll {
	padding: $xxxll;
}

.pt-0 {
	padding-top: 0;
}
.pt-xxxs {
	padding-top: $xxxs;
}
.pt-xxs {
	padding-top: $xxs;
}
.pt-xs {
	padding-top: $xs;
}
.pt-s {
	padding-top: $s;
}
.pt-m {
	padding-top: $m;
}
.pt-l {
	padding-top: $l;
}
.pt-xl {
	padding-top: $xl;
}
.pt-xxl {
	padding-top: $xxl;
}
.pt-xxxl {
	padding-top: $xxxl;
}
.pt-ll {
	padding-top: $ll;
}
.pt-xll {
	padding-top: $xll;
}
.pt-xxll {
	padding-top: $xxll;
}
.pt-xxxll {
	padding-top: $xxxll;
}

.pb-0 {
	padding-bottom: 0;
}
.pb-xxxs {
	padding-bottom: $xxxs;
}
.pb-xxs {
	padding-bottom: $xxs;
}
.pb-xs {
	padding-bottom: $xs;
}
.pb-s {
	padding-bottom: $s;
}
.pb-m {
	padding-bottom: $m;
}
.pb-l {
	padding-bottom: $l;
}
.pb-xl {
	padding-bottom: $xl;
}
.pb-xxl {
	padding-bottom: $xxl;
}
.pb-xxxl {
	padding-bottom: $xxxl;
}
.pb-ll {
	padding-bottom: $ll;
}
.pb-xll {
	padding-bottom: $xll;
}
.pb-xxll {
	padding-bottom: $xxll;
}
.pb-xxxll {
	padding-bottom: $xxxll;
}

.pl-0 {
	padding-left: 0;
}
.pl-xxxs {
	padding-left: $xxxs;
}
.pl-xxs {
	padding-left: $xxs;
}
.pl-xs {
	padding-left: $xs;
}
.pl-s {
	padding-left: $s;
}
.pl-m {
	padding-left: $m;
}
.pl-l {
	padding-left: $l;
}
.pl-xl {
	padding-left: $xl;
}
.pl-xxl {
	padding-left: $xxl;
}
.pl-xxxl {
	padding-left: $xxxl;
}
.pl-ll {
	padding-left: $ll;
}
.pl-xll {
	padding-left: $xll;
}
.pl-xxll {
	padding-left: $xxll;
}
.pl-xxxll {
	padding-left: $xxxll;
}

.pr-0 {
	padding-right: 0;
}
.pr-xxxs {
	padding-right: $xxxs;
}
.pr-xxs {
	padding-right: $xxs;
}
.pr-xs {
	padding-right: $xs;
}
.pr-s {
	padding-right: $s;
}
.pr-m {
	padding-right: $m;
}
.pr-l {
	padding-right: $l;
}
.pr-xl {
	padding-right: $xl;
}
.pr-xxl {
	padding-right: $xxl;
}
.pr-xxxl {
	padding-right: $xxxl;
}
.pr-ll {
	padding-right: $ll;
}
.pr-xll {
	padding-right: $xll;
}
.pr-xxll {
	padding-right: $xxll;
}
.pr-xxxll {
	padding-right: $xxxll;
}

.ph-0 {
	padding-left: 0;
	padding-right: 0;
}
.ph-xxxs {
	padding-left: $xxxs;
	padding-right: $xxxs;
}
.ph-xxs {
	padding-left: $xxs;
	padding-right: $xxs;
}
.ph-xs {
	padding-left: $xs;
	padding-right: $xs;
}
.ph-s {
	padding-left: $s;
	padding-right: $s;
}
.ph-m {
	padding-left: $m;
	padding-right: $m;
}
.ph-l {
	padding-left: $l;
	padding-right: $l;
}
.ph-xl {
	padding-left: $xl;
	padding-right: $xl;
}
.ph-xxl {
	padding-left: $xxl;
	padding-right: $xxl;
}
.ph-xxxl {
	padding-left: $xxxl;
	padding-right: $xxxl;
}
.ph-ll {
	padding-left: $ll;
	padding-right: $ll;
}
.ph-xll {
	padding-left: $xll;
	padding-right: $xll;
}
.ph-xxll {
	padding-left: $xxll;
	padding-right: $xxll;
}
.ph-xxxll {
	padding-left: $xxxll;
	padding-right: $xxxll;
}

.pv-0 {
	padding-top: 0;
	padding-bottom: 0;
}
.pv-xxxs {
	padding-top: $xxxs;
	padding-bottom: $xxxs;
}
.pv-xxs {
	padding-top: $xxs;
	padding-bottom: $xxs;
}
.pv-xs {
	padding-top: $xs;
	padding-bottom: $xs;
}
.pv-s {
	padding-top: $s;
	padding-bottom: $s;
}
.pv-m {
	padding-top: $m;
	padding-bottom: $m;
}
.pv-l {
	padding-top: $l;
	padding-bottom: $l;
}
.pv-xl {
	padding-top: $xl;
	padding-bottom: $xl;
}
.pv-xxl {
	padding-top: $xxl;
	padding-bottom: $xxl;
}
.pv-xxxl {
	padding-top: $xxxl;
	padding-bottom: $xxxl;
}
.pv-ll {
	padding-top: $ll;
	padding-bottom: $ll;
}
.pv-xll {
	padding-top: $xll;
	padding-bottom: $xll;
}
.pv-xxll {
	padding-top: $xxll;
	padding-bottom: $xxll;
}
.pv-xxxll {
	padding-top: $xxxll;
	padding-bottom: $xxxll;
}

.padding-0-tablet {
	@include breakpoint (medium down) {
		padding: 0;
	}
}
.padding-xxxs-tablet {
	@include breakpoint (medium down) {
		padding: $xxxs;
	}
}
.padding-xxs-tablet {
	@include breakpoint (medium down) {
		padding: $xxs;
	}
}
.padding-xs-tablet {
	@include breakpoint (medium down) {
		padding: $xs;
	}
}
.padding-s-tablet {
	@include breakpoint (medium down) {
		padding: $s;
	}
}
.padding-m-tablet {
	@include breakpoint (medium down) {
		padding: $m;
	}
}
.padding-l-tablet {
	@include breakpoint (medium down) {
		padding: $l;
	}
}
.padding-xl-tablet {
	@include breakpoint (medium down) {
		padding: $xl;
	}
}
.padding-xxl-tablet {
	@include breakpoint (medium down) {
		padding: $xxl;
	}
}
.padding-xxxl-tablet {
	@include breakpoint (medium down) {
		padding: $xxxl;
	}
}
.padding-ll-tablet {
	@include breakpoint (medium down) {
		padding: $ll;
	}
}
.padding-xll-tablet {
	@include breakpoint (medium down) {
		padding: $xll;
	}
}
.padding-xxll-tablet {
	@include breakpoint (medium down) {
		padding: $xxll;
	}
}
.padding-xxxll-tablet {
	@include breakpoint (medium down) {
		padding: $xxxll;
	}
}

.pt-0-tablet {
	@include breakpoint (medium down) {
		padding-top: 0;
	}
}
.pt-xxxs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxs;
	}
}
.pt-xxs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxs;
	}
}
.pt-xs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xs;
	}
}
.pt-s-tablet {
	@include breakpoint (medium down) {
		padding-top: $s;
	}
}
.pt-m-tablet {
	@include breakpoint (medium down) {
		padding-top: $m;
	}
}
.pt-l-tablet {
	@include breakpoint (medium down) {
		padding-top: $l;
	}
}
.pt-xl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xl;
	}
}
.pt-xxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxl;
	}
}
.pt-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxl;
	}
}
.pt-ll-tablet {
	@include breakpoint (medium down) {
		padding-top: $ll;
	}
}
.pt-xll-tablet {
	@include breakpoint (medium down) {
		padding-top: $xll;
	}
}
.pt-xxll-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxll;
	}
}
.pt-xxxll-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxll;
	}
}

.pb-0-tablet {
	@include breakpoint (medium down) {
		padding-bottom: 0;
	}
}
.pb-xxxs-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxxs;
	}
}
.pb-xxs-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxs;
	}
}
.pb-xs-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xs;
	}
}
.pb-s-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $s;
	}
}
.pb-m-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $m;
	}
}
.pb-l-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $l;
	}
}
.pb-xl-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xl;
	}
}
.pb-xxl-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxl;
	}
}
.pb-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxxl;
	}
}
.pb-ll-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $ll;
	}
}
.pb-xll-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xll;
	}
}
.pb-xxll-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxll;
	}
}
.pb-xxxll-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxxll;
	}
}

.pl-0-tablet {
	@include breakpoint (medium down) {
		padding-left: 0;
	}
}
.pl-xxxs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxs;
	}
}
.pl-xxs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxs;
	}
}
.pl-xs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xs;
	}
}
.pl-s-tablet {
	@include breakpoint (medium down) {
		padding-left: $s;
	}
}
.pl-m-tablet {
	@include breakpoint (medium down) {
		padding-left: $m;
	}
}
.pl-l-tablet {
	@include breakpoint (medium down) {
		padding-left: $l;
	}
}
.pl-xl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xl;
	}
}
.pl-xxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxl;
	}
}
.pl-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxl;
	}
}
.pl-ll-tablet {
	@include breakpoint (medium down) {
		padding-left: $ll;
	}
}
.pl-xll-tablet {
	@include breakpoint (medium down) {
		padding-left: $xll;
	}
}
.pl-xxll-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxll;
	}
}
.pl-xxxll-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxll;
	}
}

.pr-0-tablet {
	@include breakpoint (medium down) {
		padding-right: 0;
	}
}
.pr-xxxs-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxxs;
	}
}
.pr-xxs-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxs;
	}
}
.pr-xs-tablet {
	@include breakpoint (medium down) {
		padding-right: $xs;
	}
}
.pr-s-tablet {
	@include breakpoint (medium down) {
		padding-right: $s;
	}
}
.pr-m-tablet {
	@include breakpoint (medium down) {
		padding-right: $m;
	}
}
.pr-l-tablet {
	@include breakpoint (medium down) {
		padding-right: $l;
	}
}
.pr-xl-tablet {
	@include breakpoint (medium down) {
		padding-right: $xl;
	}
}
.pr-xxl-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxl;
	}
}
.pr-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxxl;
	}
}
.pr-ll-tablet {
	@include breakpoint (medium down) {
		padding-right: $ll;
	}
}
.pr-xll-tablet {
	@include breakpoint (medium down) {
		padding-right: $xll;
	}
}
.pr-xxll-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxll;
	}
}
.pr-xxxll-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxxll;
	}
}

.ph-0-tablet {
	@include breakpoint (medium down) {
		padding-left: 0;
		padding-right: 0;
	}
}
.ph-xxxs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxs;
		padding-right: $xxxs;
	}
}
.ph-xxs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxs;
		padding-right: $xxs;
	}
}
.ph-xs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xs;
		padding-right: $xs;
	}
}
.ph-s-tablet {
	@include breakpoint (medium down) {
		padding-left: $s;
		padding-right: $s;
	}
}
.ph-m-tablet {
	@include breakpoint (medium down) {
		padding-left: $m;
		padding-right: $m;
	}
}
.ph-l-tablet {
	@include breakpoint (medium down) {
		padding-left: $l;
		padding-right: $l;
	}
}
.ph-xl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xl;
		padding-right: $xl;
	}
}
.ph-xxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxl;
		padding-right: $xxl;
	}
}
.ph-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxl;
		padding-right: $xxxl;
	}
}
.ph-ll-tablet {
	@include breakpoint (medium down) {
		padding-left: $ll;
		padding-right: $ll;
	}
}
.ph-xll-tablet {
	@include breakpoint (medium down) {
		padding-left: $xll;
		padding-right: $xll;
	}
}
.ph-xxll-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxll;
		padding-right: $xxll;
	}
}
.ph-xxxll-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxll;
		padding-right: $xxxll;
	}
}

.pv-0-tablet {
	@include breakpoint (medium down) {
		padding-top: 0;
		padding-bottom: 0;
	}
}
.pv-xxxs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxs;
		padding-bottom: $xxxs;
	}
}
.pv-xxs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxs;
		padding-bottom: $xxs;
	}
}
.pv-xs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xs;
		padding-bottom: $xs;
	}
}
.pv-s-tablet {
	@include breakpoint (medium down) {
		padding-top: $s;
		padding-bottom: $s;
	}
}
.pv-m-tablet {
	@include breakpoint (medium down) {
		padding-top: $m;
		padding-bottom: $m;
	}
}
.pv-l-tablet {
	@include breakpoint (medium down) {
		padding-top: $l;
		padding-bottom: $l;
	}
}
.pv-xl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xl;
		padding-bottom: $xl;
	}
}
.pv-xxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxl;
		padding-bottom: $xxl;
	}
}
.pv-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxl;
		padding-bottom: $xxxl;
	}
}
.pv-ll-tablet {
	@include breakpoint (medium down) {
		padding-top: $ll;
		padding-bottom: $ll;
	}
}
.pv-xll-tablet {
	@include breakpoint (medium down) {
		padding-top: $xll;
		padding-bottom: $xll;
	}
}
.pv-xxll-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxll;
		padding-bottom: $xxll;
	}
}
.pv-xxxll-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxll;
		padding-bottom: $xxxll;
	}
}

.padding-0-mobile {
	@include breakpoint (small down) {
		padding: 0;
	}
}
.padding-xxxs-mobile {
	@include breakpoint (small down) {
		padding: $xxxs;
	}
}
.padding-xxs-mobile {
	@include breakpoint (small down) {
		padding: $xxs;
	}
}
.padding-xs-mobile {
	@include breakpoint (small down) {
		padding: $xs;
	}
}
.padding-s-mobile {
	@include breakpoint (small down) {
		padding: $s;
	}
}
.padding-m-mobile {
	@include breakpoint (small down) {
		padding: $m;
	}
}
.padding-l-mobile {
	@include breakpoint (small down) {
		padding: $l;
	}
}
.padding-xl-mobile {
	@include breakpoint (small down) {
		padding: $xl;
	}
}
.padding-xxl-mobile {
	@include breakpoint (small down) {
		padding: $xxl;
	}
}
.padding-xxxl-mobile {
	@include breakpoint (small down) {
		padding: $xxxl;
	}
}
.padding-ll-mobile {
	@include breakpoint (small down) {
		padding: $ll;
	}
}
.padding-xll-mobile {
	@include breakpoint (small down) {
		padding: $xll;
	}
}
.padding-xxll-mobile {
	@include breakpoint (small down) {
		padding: $xxll;
	}
}
.padding-xxxll-mobile {
	@include breakpoint (small down) {
		padding: $xxxll;
	}
}

.pt-0-mobile {
	@include breakpoint (small down) {
		padding-top: 0;
	}
}
.pt-xxxs-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxs;
	}
}
.pt-xxs-mobile {
	@include breakpoint (small down) {
		padding-top: $xxs;
	}
}
.pt-xs-mobile {
	@include breakpoint (small down) {
		padding-top: $xs;
	}
}
.pt-s-mobile {
	@include breakpoint (small down) {
		padding-top: $s;
	}
}
.pt-m-mobile {
	@include breakpoint (small down) {
		padding-top: $m;
	}
}
.pt-l-mobile {
	@include breakpoint (small down) {
		padding-top: $l;
	}
}
.pt-xl-mobile {
	@include breakpoint (small down) {
		padding-top: $xl;
	}
}
.pt-xxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxl;
	}
}
.pt-xxxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxl;
	}
}
.pt-ll-mobile {
	@include breakpoint (small down) {
		padding-top: $ll;
	}
}
.pt-xll-mobile {
	@include breakpoint (small down) {
		padding-top: $xll;
	}
}
.pt-xxll-mobile {
	@include breakpoint (small down) {
		padding-top: $xxll;
	}
}
.pt-xxxll-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxll;
	}
}

.pb-0-mobile {
	@include breakpoint (small down) {
		padding-bottom: 0;
	}
}
.pb-xxxs-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxxs;
	}
}
.pb-xxs-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxs;
	}
}
.pb-xs-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xs;
	}
}
.pb-s-mobile {
	@include breakpoint (small down) {
		padding-bottom: $s;
	}
}
.pb-m-mobile {
	@include breakpoint (small down) {
		padding-bottom: $m;
	}
}
.pb-l-mobile {
	@include breakpoint (small down) {
		padding-bottom: $l;
	}
}
.pb-xl-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xl;
	}
}
.pb-xxl-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxl;
	}
}
.pb-xxxl-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxxl;
	}
}
.pb-ll-mobile {
	@include breakpoint (small down) {
		padding-bottom: $ll;
	}
}
.pb-xll-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xll;
	}
}
.pb-xxll-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxll;
	}
}
.pb-xxxll-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxxll;
	}
}

.pl-0-mobile {
	@include breakpoint (small down) {
		padding-left: 0;
	}
}
.pl-xxxs-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxs;
	}
}
.pl-xxs-mobile {
	@include breakpoint (small down) {
		padding-left: $xxs;
	}
}
.pl-xs-mobile {
	@include breakpoint (small down) {
		padding-left: $xs;
	}
}
.pl-s-mobile {
	@include breakpoint (small down) {
		padding-left: $s;
	}
}
.pl-m-mobile {
	@include breakpoint (small down) {
		padding-left: $m;
	}
}
.pl-l-mobile {
	@include breakpoint (small down) {
		padding-left: $l;
	}
}
.pl-xl-mobile {
	@include breakpoint (small down) {
		padding-left: $xl;
	}
}
.pl-xxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxl;
	}
}
.pl-xxxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxl;
	}
}
.pl-ll-mobile {
	@include breakpoint (small down) {
		padding-left: $ll;
	}
}
.pl-xll-mobile {
	@include breakpoint (small down) {
		padding-left: $xll;
	}
}
.pl-xxll-mobile {
	@include breakpoint (small down) {
		padding-left: $xxll;
	}
}
.pl-xxxll-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxll;
	}
}

.pr-0-mobile {
	@include breakpoint (small down) {
		padding-right: 0;
	}
}
.pr-xxxs-mobile {
	@include breakpoint (small down) {
		padding-right: $xxxs;
	}
}
.pr-xxs-mobile {
	@include breakpoint (small down) {
		padding-right: $xxs;
	}
}
.pr-xs-mobile {
	@include breakpoint (small down) {
		padding-right: $xs;
	}
}
.pr-s-mobile {
	@include breakpoint (small down) {
		padding-right: $s;
	}
}
.pr-m-mobile {
	@include breakpoint (small down) {
		padding-right: $m;
	}
}
.pr-l-mobile {
	@include breakpoint (small down) {
		padding-right: $l;
	}
}
.pr-xl-mobile {
	@include breakpoint (small down) {
		padding-right: $xl;
	}
}
.pr-xxl-mobile {
	@include breakpoint (small down) {
		padding-right: $xxl;
	}
}
.pr-xxxl-mobile {
	@include breakpoint (small down) {
		padding-right: $xxxl;
	}
}
.pr-ll-mobile {
	@include breakpoint (small down) {
		padding-right: $ll;
	}
}
.pr-xll-mobile {
	@include breakpoint (small down) {
		padding-right: $xll;
	}
}
.pr-xxll-mobile {
	@include breakpoint (small down) {
		padding-right: $xxll;
	}
}
.pr-xxxll-mobile {
	@include breakpoint (small down) {
		padding-right: $xxxll;
	}
}

.ph-0-mobile {
	@include breakpoint (small down) {
		padding-left: 0;
		padding-right: 0;
	}
}
.ph-xxxs-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxs;
		padding-right: $xxxs;
	}
}
.ph-xxs-mobile {
	@include breakpoint (small down) {
		padding-left: $xxs;
		padding-right: $xxs;
	}
}
.ph-xs-mobile {
	@include breakpoint (small down) {
		padding-left: $xs;
		padding-right: $xs;
	}
}
.ph-s-mobile {
	@include breakpoint (small down) {
		padding-left: $s;
		padding-right: $s;
	}
}
.ph-m-mobile {
	@include breakpoint (small down) {
		padding-left: $m;
		padding-right: $m;
	}
}
.ph-l-mobile {
	@include breakpoint (small down) {
		padding-left: $l;
		padding-right: $l;
	}
}
.ph-xl-mobile {
	@include breakpoint (small down) {
		padding-left: $xl;
		padding-right: $xl;
	}
}
.ph-xxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxl;
		padding-right: $xxl;
	}
}
.ph-xxxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxl;
		padding-right: $xxxl;
	}
}
.ph-ll-mobile {
	@include breakpoint (small down) {
		padding-left: $ll;
		padding-right: $ll;
	}
}
.ph-xll-mobile {
	@include breakpoint (small down) {
		padding-left: $xll;
		padding-right: $xll;
	}
}
.ph-xxll-mobile {
	@include breakpoint (small down) {
		padding-left: $xxll;
		padding-right: $xxll;
	}
}
.ph-xxxll-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxll;
		padding-right: $xxxll;
	}
}

.pv-0-mobile {
	@include breakpoint (small down) {
		padding-top: 0;
		padding-bottom: 0;
	}
}
.pv-xxxs-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxs;
		padding-bottom: $xxxs;
	}
}
.pv-xxs-mobile {
	@include breakpoint (small down) {
		padding-top: $xxs;
		padding-bottom: $xxs;
	}
}
.pv-xs-mobile {
	@include breakpoint (small down) {
		padding-top: $xs;
		padding-bottom: $xs;
	}
}
.pv-s-mobile {
	@include breakpoint (small down) {
		padding-top: $s;
		padding-bottom: $s;
	}
}
.pv-m-mobile {
	@include breakpoint (small down) {
		padding-top: $m;
		padding-bottom: $m;
	}
}
.pv-l-mobile {
	@include breakpoint (small down) {
		padding-top: $l;
		padding-bottom: $l;
	}
}
.pv-xl-mobile {
	@include breakpoint (small down) {
		padding-top: $xl;
		padding-bottom: $xl;
	}
}
.pv-xxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxl;
		padding-bottom: $xxl;
	}
}
.pv-xxxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxl;
		padding-bottom: $xxxl;
	}
}
.pv-ll-mobile {
	@include breakpoint (small down) {
		padding-top: $ll;
		padding-bottom: $ll;
	}
}
.pv-xll-mobile {
	@include breakpoint (small down) {
		padding-top: $xll;
		padding-bottom: $xll;
	}
}
.pv-xxll-mobile {
	@include breakpoint (small down) {
		padding-top: $xxll;
		padding-bottom: $xxll;
	}
}
.pv-xxxll-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxll;
		padding-bottom: $xxxll;
	}
}


// MARGIN MIXINS
@mixin margin-0 {
	margin: 0;
}
@mixin margin-xxxs {
	margin: $xxxs;
}
@mixin margin-xxs {
	margin: $xxs;
}
@mixin margin-xs {
	margin: $xs;
}
@mixin margin-s {
	margin: $s;
}
@mixin margin-m {
	margin: $m;
}
@mixin margin-l {
	margin: $l;
}
@mixin margin-xl {
	margin: $xl;
}
@mixin margin-xxl {
	margin: $xxl;
}
@mixin margin-xxxl {
	margin: $xxxl;
}
@mixin margin-ll {
	margin: $ll;
}
@mixin margin-xll {
	margin: $xll;
}
@mixin margin-xxll {
	margin: $xxll;
}
@mixin margin-xxxll {
	margin: $xxxll;
}

@mixin mt-0 {
	margin-top: 0;
}
@mixin mt-xxxs {
	margin-top: $xxxs;
}
@mixin mt-xxs {
	margin-top: $xxs;
}
@mixin mt-xs {
	margin-top: $xs;
}
@mixin mt-s {
	margin-top: $s;
}
@mixin mt-m {
	margin-top: $m;
}
@mixin mt-l {
	margin-top: $l;
}
@mixin mt-xl {
	margin-top: $xl;
}
@mixin mt-xxl {
	margin-top: $xxl;
}
@mixin mt-xxxl {
	margin-top: $xxxl;
}
@mixin mt-ll {
	margin-top: $ll;
}
@mixin mt-xll {
	margin-top: $xll;
}
@mixin mt-xxll {
	margin-top: $xxll;
}
@mixin mt-xxxll {
	margin-top: $xxxll;
}

@mixin mb-0 {
	margin-bottom: 0;
}
@mixin mb-xxxs {
	margin-bottom: $xxxs;
}
@mixin mb-xxs {
	margin-bottom: $xxs;
}
@mixin mb-xs {
	margin-bottom: $xs;
}
@mixin mb-s {
	margin-bottom: $s;
}
@mixin mb-m {
	margin-bottom: $m;
}
@mixin mb-l {
	margin-bottom: $l;
}
@mixin mb-xl {
	margin-bottom: $xl;
}
@mixin mb-xxl {
	margin-bottom: $xxl;
}
@mixin mb-xxxl {
	margin-bottom: $xxxl;
}
@mixin mb-ll {
	margin-bottom: $ll;
}
@mixin mb-xll {
	margin-bottom: $xll;
}
@mixin mb-xxll {
	margin-bottom: $xxll;
}
@mixin mb-xxxll {
	margin-bottom: $xxxll;
}

@mixin ml-0 {
	margin-left: 0;
}
@mixin ml-xxxs {
	margin-left: $xxxs;
}
@mixin ml-xxs {
	margin-left: $xxs;
}
@mixin ml-xs {
	margin-left: $xs;
}
@mixin ml-s {
	margin-left: $s;
}
@mixin ml-m {
	margin-left: $m;
}
@mixin ml-l {
	margin-left: $l;
}
@mixin ml-xl {
	margin-left: $xl;
}
@mixin ml-xxl {
	margin-left: $xxl;
}
@mixin ml-xxxl {
	margin-left: $xxxl;
}
@mixin ml-ll {
	margin-left: $ll;
}
@mixin ml-xll {
	margin-left: $xll;
}
@mixin ml-xxll {
	margin-left: $xxll;
}
@mixin ml-xxxll {
	margin-left: $xxxll;
}

@mixin mr-0 {
	margin-right: 0;
}
@mixin mr-xxxs {
	margin-right: $xxxs;
}
@mixin mr-xxs {
	margin-right: $xxs;
}
@mixin mr-xs {
	margin-right: $xs;
}
@mixin mr-s {
	margin-right: $s;
}
@mixin mr-m {
	margin-right: $m;
}
@mixin mr-l {
	margin-right: $l;
}
@mixin mr-xl {
	margin-right: $xl;
}
@mixin mr-xxl {
	margin-right: $xxl;
}
@mixin mr-xxxl {
	margin-right: $xxxl;
}
@mixin mr-ll {
	margin-right: $ll;
}
@mixin mr-xll {
	margin-right: $xll;
}
@mixin mr-xxll {
	margin-right: $xxll;
}
@mixin mr-xxxll {
	margin-right: $xxxll;
}

@mixin mh-0 {
	margin-left: 0;
	margin-right: 0;
}
@mixin mh-xxxs {
	margin-left: $xxxs;
	margin-right: $xxxs;
}
@mixin mh-xxs {
	margin-left: $xxs;
	margin-right: $xxs;
}
@mixin mh-xs {
	margin-left: $xs;
	margin-right: $xs;
}
@mixin mh-s {
	margin-left: $s;
	margin-right: $s;
}
@mixin mh-m {
	margin-left: $m;
	margin-right: $m;
}
@mixin mh-l {
	margin-left: $l;
	margin-right: $l;
}
@mixin mh-xl {
	margin-left: $xl;
	margin-right: $xl;
}
@mixin mh-xxl {
	margin-left: $xxl;
	margin-right: $xxl;
}
@mixin mh-xxxl {
	margin-left: $xxxl;
	margin-right: $xxxl;
}
@mixin mh-ll {
	margin-left: $ll;
	margin-right: $ll;
}
@mixin mh-xll {
	margin-left: $xll;
	margin-right: $xll;
}
@mixin mh-xxll {
	margin-left: $xxll;
	margin-right: $xxll;
}
@mixin mh-xxxll {
	margin-left: $xxxll;
	margin-right: $xxxll;
}

@mixin mv-0 {
	margin-top: 0;
	margin-bottom: 0;
}
@mixin mv-xxxs {
	margin-top: $xxxs;
	margin-bottom: $xxxs;
}
@mixin mv-xxs {
	margin-top: $xxs;
	margin-bottom: $xxs;
}
@mixin mv-xs {
	margin-top: $xs;
	margin-bottom: $xs;
}
@mixin mv-s {
	margin-top: $s;
	margin-bottom: $s;
}
@mixin mv-m {
	margin-top: $m;
	margin-bottom: $m;
}
@mixin mv-l {
	margin-top: $l;
	margin-bottom: $l;
}
@mixin mv-xl {
	margin-top: $xl;
	margin-bottom: $xl;
}
@mixin mv-xxl {
	margin-top: $xxl;
	margin-bottom: $xxl;
}
@mixin mv-xxxl {
	margin-top: $xxxl;
	margin-bottom: $xxxl;
}
@mixin mv-ll {
	margin-top: $ll;
	margin-bottom: $ll;
}
@mixin mv-xll {
	margin-top: $xll;
	margin-bottom: $xll;
}
@mixin mv-xxll {
	margin-top: $xxll;
	margin-bottom: $xxll;
}
@mixin mv-xxxll {
	margin-top: $xxxll;
	margin-bottom: $xxxll;
}

@mixin margin-0-tablet {
	@include breakpoint (medium down) {
		margin: 0;
	}
}
@mixin margin-xxxs-tablet {
	@include breakpoint (medium down) {
		margin: $xxxs;
	}
}
@mixin margin-xxs-tablet {
	@include breakpoint (medium down) {
		margin: $xxs;
	}
}
@mixin margin-xs-tablet {
	@include breakpoint (medium down) {
		margin: $xs;
	}
}
@mixin margin-s-tablet {
	@include breakpoint (medium down) {
		margin: $s;
	}
}
@mixin margin-m-tablet {
	@include breakpoint (medium down) {
		margin: $m;
	}
}
@mixin margin-l-tablet {
	@include breakpoint (medium down) {
		margin: $l;
	}
}
@mixin margin-xl-tablet {
	@include breakpoint (medium down) {
		margin: $xl;
	}
}
@mixin margin-xxl-tablet {
	@include breakpoint (medium down) {
		margin: $xxl;
	}
}
@mixin margin-xxxl-tablet {
	@include breakpoint (medium down) {
		margin: $xxxl;
	}
}
@mixin margin-ll-tablet {
	@include breakpoint (medium down) {
		margin: $ll;
	}
}
@mixin margin-xll-tablet {
	@include breakpoint (medium down) {
		margin: $xll;
	}
}
@mixin margin-xxll-tablet {
	@include breakpoint (medium down) {
		margin: $xxll;
	}
}
@mixin margin-xxxll-tablet {
	@include breakpoint (medium down) {
		margin: $xxxll;
	}
}

@mixin mt-0-tablet {
	@include breakpoint (medium down) {
		margin-top: 0;
	}
}
@mixin mt-xxxs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxs;
	}
}
@mixin mt-xxs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxs;
	}
}
@mixin mt-xs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xs;
	}
}
@mixin mt-s-tablet {
	@include breakpoint (medium down) {
		margin-top: $s;
	}
}
@mixin mt-m-tablet {
	@include breakpoint (medium down) {
		margin-top: $m;
	}
}
@mixin mt-l-tablet {
	@include breakpoint (medium down) {
		margin-top: $l;
	}
}
@mixin mt-xl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xl;
	}
}
@mixin mt-xxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxl;
	}
}
@mixin mt-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxl;
	}
}
@mixin mt-ll-tablet {
	@include breakpoint (medium down) {
		margin-top: $ll;
	}
}
@mixin mt-xll-tablet {
	@include breakpoint (medium down) {
		margin-top: $xll;
	}
}
@mixin mt-xxll-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxll;
	}
}
@mixin mt-xxxll-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxll;
	}
}

@mixin mb-0-tablet {
	@include breakpoint (medium down) {
		margin-bottom: 0;
	}
}
@mixin mb-xxxs-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxxs;
	}
}
@mixin mb-xxs-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxs;
	}
}
@mixin mb-xs-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xs;
	}
}
@mixin mb-s-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $s;
	}
}
@mixin mb-m-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $m;
	}
}
@mixin mb-l-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $l;
	}
}
@mixin mb-xl-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xl;
	}
}
@mixin mb-xxl-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxl;
	}
}
@mixin mb-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxxl;
	}
}
@mixin mb-ll-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $ll;
	}
}
@mixin mb-xll-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xll;
	}
}
@mixin mb-xxll-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxll;
	}
}
@mixin mb-xxxll-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxxll;
	}
}

@mixin ml-0-tablet {
	@include breakpoint (medium down) {
		margin-left: 0;
	}
}
@mixin ml-xxxs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxs;
	}
}
@mixin ml-xxs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxs;
	}
}
@mixin ml-xs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xs;
	}
}
@mixin ml-s-tablet {
	@include breakpoint (medium down) {
		margin-left: $s;
	}
}
@mixin ml-m-tablet {
	@include breakpoint (medium down) {
		margin-left: $m;
	}
}
@mixin ml-l-tablet {
	@include breakpoint (medium down) {
		margin-left: $l;
	}
}
@mixin ml-xl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xl;
	}
}
@mixin ml-xxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxl;
	}
}
@mixin ml-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxl;
	}
}
@mixin ml-ll-tablet {
	@include breakpoint (medium down) {
		margin-left: $ll;
	}
}
@mixin ml-xll-tablet {
	@include breakpoint (medium down) {
		margin-left: $xll;
	}
}
@mixin ml-xxll-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxll;
	}
}
@mixin ml-xxxll-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxll;
	}
}

@mixin mr-0-tablet {
	@include breakpoint (medium down) {
		margin-right: 0;
	}
}
@mixin mr-xxxs-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxxs;
	}
}
@mixin mr-xxs-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxs;
	}
}
@mixin mr-xs-tablet {
	@include breakpoint (medium down) {
		margin-right: $xs;
	}
}
@mixin mr-s-tablet {
	@include breakpoint (medium down) {
		margin-right: $s;
	}
}
@mixin mr-m-tablet {
	@include breakpoint (medium down) {
		margin-right: $m;
	}
}
@mixin mr-l-tablet {
	@include breakpoint (medium down) {
		margin-right: $l;
	}
}
@mixin mr-xl-tablet {
	@include breakpoint (medium down) {
		margin-right: $xl;
	}
}
@mixin mr-xxl-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxl;
	}
}
@mixin mr-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxxl;
	}
}
@mixin mr-ll-tablet {
	@include breakpoint (medium down) {
		margin-right: $ll;
	}
}
@mixin mr-xll-tablet {
	@include breakpoint (medium down) {
		margin-right: $xll;
	}
}
@mixin mr-xxll-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxll;
	}
}
@mixin mr-xxxll-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxxll;
	}
}

@mixin mh-0-tablet {
	@include breakpoint (medium down) {
		margin-left: 0;
		margin-right: 0;
	}
}
@mixin mh-xxxs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxs;
		margin-right: $xxxs;
	}
}
@mixin mh-xxs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxs;
		margin-right: $xxs;
	}
}
@mixin mh-xs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xs;
		margin-right: $xs;
	}
}
@mixin mh-s-tablet {
	@include breakpoint (medium down) {
		margin-left: $s;
		margin-right: $s;
	}
}
@mixin mh-m-tablet {
	@include breakpoint (medium down) {
		margin-left: $m;
		margin-right: $m;
	}
}
@mixin mh-l-tablet {
	@include breakpoint (medium down) {
		margin-left: $l;
		margin-right: $l;
	}
}
@mixin mh-xl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xl;
		margin-right: $xl;
	}
}
@mixin mh-xxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxl;
		margin-right: $xxl;
	}
}
@mixin mh-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxl;
		margin-right: $xxxl;
	}
}
@mixin mh-ll-tablet {
	@include breakpoint (medium down) {
		margin-left: $ll;
		margin-right: $ll;
	}
}
@mixin mh-xll-tablet {
	@include breakpoint (medium down) {
		margin-left: $xll;
		margin-right: $xll;
	}
}
@mixin mh-xxll-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxll;
		margin-right: $xxll;
	}
}
@mixin mh-xxxll-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxll;
		margin-right: $xxxll;
	}
}

@mixin mv-0-tablet {
	@include breakpoint (medium down) {
		margin-top: 0;
		margin-bottom: 0;
	}
}
@mixin mv-xxxs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxs;
		margin-bottom: $xxxs;
	}
}
@mixin mv-xxs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxs;
		margin-bottom: $xxs;
	}
}
@mixin mv-xs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xs;
		margin-bottom: $xs;
	}
}
@mixin mv-s-tablet {
	@include breakpoint (medium down) {
		margin-top: $s;
		margin-bottom: $s;
	}
}
@mixin mv-m-tablet {
	@include breakpoint (medium down) {
		margin-top: $m;
		margin-bottom: $m;
	}
}
@mixin mv-l-tablet {
	@include breakpoint (medium down) {
		margin-top: $l;
		margin-bottom: $l;
	}
}
@mixin mv-xl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xl;
		margin-bottom: $xl;
	}
}
@mixin mv-xxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxl;
		margin-bottom: $xxl;
	}
}
@mixin mv-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxl;
		margin-bottom: $xxxl;
	}
}
@mixin mv-ll-tablet {
	@include breakpoint (medium down) {
		margin-top: $ll;
		margin-bottom: $ll;
	}
}
@mixin mv-xll-tablet {
	@include breakpoint (medium down) {
		margin-top: $xll;
		margin-bottom: $xll;
	}
}
@mixin mv-xxll-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxll;
		margin-bottom: $xxll;
	}
}
@mixin mv-xxxll-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxll;
		margin-bottom: $xxxll;
	}
}

@mixin margin-0-mobile {
	@include breakpoint (small down) {
		margin: 0;
	}
}
@mixin margin-xxxs-mobile {
	@include breakpoint (small down) {
		margin: $xxxs;
	}
}
@mixin margin-xxs-mobile {
	@include breakpoint (small down) {
		margin: $xxs;
	}
}
@mixin margin-xs-mobile {
	@include breakpoint (small down) {
		margin: $xs;
	}
}
@mixin margin-s-mobile {
	@include breakpoint (small down) {
		margin: $s;
	}
}
@mixin margin-m-mobile {
	@include breakpoint (small down) {
		margin: $m;
	}
}
@mixin margin-l-mobile {
	@include breakpoint (small down) {
		margin: $l;
	}
}
@mixin margin-xl-mobile {
	@include breakpoint (small down) {
		margin: $xl;
	}
}
@mixin margin-xxl-mobile {
	@include breakpoint (small down) {
		margin: $xxl;
	}
}
@mixin margin-xxxl-mobile {
	@include breakpoint (small down) {
		margin: $xxxl;
	}
}
@mixin margin-ll-mobile {
	@include breakpoint (small down) {
		margin: $ll;
	}
}
@mixin margin-xll-mobile {
	@include breakpoint (small down) {
		margin: $xll;
	}
}
@mixin margin-xxll-mobile {
	@include breakpoint (small down) {
		margin: $xxll;
	}
}
@mixin margin-xxxll-mobile {
	@include breakpoint (small down) {
		margin: $xxxll;
	}
}

@mixin mt-0-mobile {
	@include breakpoint (small down) {
		margin-top: 0;
	}
}
@mixin mt-xxxs-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxs;
	}
}
@mixin mt-xxs-mobile {
	@include breakpoint (small down) {
		margin-top: $xxs;
	}
}
@mixin mt-xs-mobile {
	@include breakpoint (small down) {
		margin-top: $xs;
	}
}
@mixin mt-s-mobile {
	@include breakpoint (small down) {
		margin-top: $s;
	}
}
@mixin mt-m-mobile {
	@include breakpoint (small down) {
		margin-top: $m;
	}
}
@mixin mt-l-mobile {
	@include breakpoint (small down) {
		margin-top: $l;
	}
}
@mixin mt-xl-mobile {
	@include breakpoint (small down) {
		margin-top: $xl;
	}
}
@mixin mt-xxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxl;
	}
}
@mixin mt-xxxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxl;
	}
}
@mixin mt-ll-mobile {
	@include breakpoint (small down) {
		margin-top: $ll;
	}
}
@mixin mt-xll-mobile {
	@include breakpoint (small down) {
		margin-top: $xll;
	}
}
@mixin mt-xxll-mobile {
	@include breakpoint (small down) {
		margin-top: $xxll;
	}
}
@mixin mt-xxxll-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxll;
	}
}

@mixin mb-0-mobile {
	@include breakpoint (small down) {
		margin-bottom: 0;
	}
}
@mixin mb-xxxs-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxxs;
	}
}
@mixin mb-xxs-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxs;
	}
}
@mixin mb-xs-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xs;
	}
}
@mixin mb-s-mobile {
	@include breakpoint (small down) {
		margin-bottom: $s;
	}
}
@mixin mb-m-mobile {
	@include breakpoint (small down) {
		margin-bottom: $m;
	}
}
@mixin mb-l-mobile {
	@include breakpoint (small down) {
		margin-bottom: $l;
	}
}
@mixin mb-xl-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xl;
	}
}
@mixin mb-xxl-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxl;
	}
}
@mixin mb-xxxl-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxxl;
	}
}
@mixin mb-ll-mobile {
	@include breakpoint (small down) {
		margin-bottom: $ll;
	}
}
@mixin mb-xll-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xll;
	}
}
@mixin mb-xxll-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxll;
	}
}
@mixin mb-xxxll-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxxll;
	}
}

@mixin ml-0-mobile {
	@include breakpoint (small down) {
		margin-left: 0;
	}
}
@mixin ml-xxxs-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxs;
	}
}
@mixin ml-xxs-mobile {
	@include breakpoint (small down) {
		margin-left: $xxs;
	}
}
@mixin ml-xs-mobile {
	@include breakpoint (small down) {
		margin-left: $xs;
	}
}
@mixin ml-s-mobile {
	@include breakpoint (small down) {
		margin-left: $s;
	}
}
@mixin ml-m-mobile {
	@include breakpoint (small down) {
		margin-left: $m;
	}
}
@mixin ml-l-mobile {
	@include breakpoint (small down) {
		margin-left: $l;
	}
}
@mixin ml-xl-mobile {
	@include breakpoint (small down) {
		margin-left: $xl;
	}
}
@mixin ml-xxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxl;
	}
}
@mixin ml-xxxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxl;
	}
}
@mixin ml-ll-mobile {
	@include breakpoint (small down) {
		margin-left: $ll;
	}
}
@mixin ml-xll-mobile {
	@include breakpoint (small down) {
		margin-left: $xll;
	}
}
@mixin ml-xxll-mobile {
	@include breakpoint (small down) {
		margin-left: $xxll;
	}
}
@mixin ml-xxxll-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxll;
	}
}

@mixin mr-0-mobile {
	@include breakpoint (small down) {
		margin-right: 0;
	}
}
@mixin mr-xxxs-mobile {
	@include breakpoint (small down) {
		margin-right: $xxxs;
	}
}
@mixin mr-xxs-mobile {
	@include breakpoint (small down) {
		margin-right: $xxs;
	}
}
@mixin mr-xs-mobile {
	@include breakpoint (small down) {
		margin-right: $xs;
	}
}
@mixin mr-s-mobile {
	@include breakpoint (small down) {
		margin-right: $s;
	}
}
@mixin mr-m-mobile {
	@include breakpoint (small down) {
		margin-right: $m;
	}
}
@mixin mr-l-mobile {
	@include breakpoint (small down) {
		margin-right: $l;
	}
}
@mixin mr-xl-mobile {
	@include breakpoint (small down) {
		margin-right: $xl;
	}
}
@mixin mr-xxl-mobile {
	@include breakpoint (small down) {
		margin-right: $xxl;
	}
}
@mixin mr-xxxl-mobile {
	@include breakpoint (small down) {
		margin-right: $xxxl;
	}
}
@mixin mr-ll-mobile {
	@include breakpoint (small down) {
		margin-right: $ll;
	}
}
@mixin mr-xll-mobile {
	@include breakpoint (small down) {
		margin-right: $xll;
	}
}
@mixin mr-xxll-mobile {
	@include breakpoint (small down) {
		margin-right: $xxll;
	}
}
@mixin mr-xxxll-mobile {
	@include breakpoint (small down) {
		margin-right: $xxxll;
	}
}

@mixin mh-0-mobile {
	@include breakpoint (small down) {
		margin-left: 0;
		margin-right: 0;
	}
}
@mixin mh-xxxs-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxs;
		margin-right: $xxxs;
	}
}
@mixin mh-xxs-mobile {
	@include breakpoint (small down) {
		margin-left: $xxs;
		margin-right: $xxs;
	}
}
@mixin mh-xs-mobile {
	@include breakpoint (small down) {
		margin-left: $xs;
		margin-right: $xs;
	}
}
@mixin mh-s-mobile {
	@include breakpoint (small down) {
		margin-left: $s;
		margin-right: $s;
	}
}
@mixin mh-m-mobile {
	@include breakpoint (small down) {
		margin-left: $m;
		margin-right: $m;
	}
}
@mixin mh-l-mobile {
	@include breakpoint (small down) {
		margin-left: $l;
		margin-right: $l;
	}
}
@mixin mh-xl-mobile {
	@include breakpoint (small down) {
		margin-left: $xl;
		margin-right: $xl;
	}
}
@mixin mh-xxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxl;
		margin-right: $xxl;
	}
}
@mixin mh-xxxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxl;
		margin-right: $xxxl;
	}
}
@mixin mh-ll-mobile {
	@include breakpoint (small down) {
		margin-left: $ll;
		margin-right: $ll;
	}
}
@mixin mh-xll-mobile {
	@include breakpoint (small down) {
		margin-left: $xll;
		margin-right: $xll;
	}
}
@mixin mh-xxll-mobile {
	@include breakpoint (small down) {
		margin-left: $xxll;
		margin-right: $xxll;
	}
}
@mixin mh-xxxll-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxll;
		margin-right: $xxxll;
	}
}

@mixin mv-0-mobile {
	@include breakpoint (small down) {
		margin-top: 0;
		margin-bottom: 0;
	}
}
@mixin mv-xxxs-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxs;
		margin-bottom: $xxxs;
	}
}
@mixin mv-xxs-mobile {
	@include breakpoint (small down) {
		margin-top: $xxs;
		margin-bottom: $xxs;
	}
}
@mixin mv-xs-mobile {
	@include breakpoint (small down) {
		margin-top: $xs;
		margin-bottom: $xs;
	}
}
@mixin mv-s-mobile {
	@include breakpoint (small down) {
		margin-top: $s;
		margin-bottom: $s;
	}
}
@mixin mv-m-mobile {
	@include breakpoint (small down) {
		margin-top: $m;
		margin-bottom: $m;
	}
}
@mixin mv-l-mobile {
	@include breakpoint (small down) {
		margin-top: $l;
		margin-bottom: $l;
	}
}
@mixin mv-xl-mobile {
	@include breakpoint (small down) {
		margin-top: $xl;
		margin-bottom: $xl;
	}
}
@mixin mv-xxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxl;
		margin-bottom: $xxl;
	}
}
@mixin mv-xxxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxl;
		margin-bottom: $xxxl;
	}
}
@mixin mv-ll-mobile {
	@include breakpoint (small down) {
		margin-top: $ll;
		margin-bottom: $ll;
	}
}
@mixin mv-xll-mobile {
	@include breakpoint (small down) {
		margin-top: $xll;
		margin-bottom: $xll;
	}
}
@mixin mv-xxll-mobile {
	@include breakpoint (small down) {
		margin-top: $xxll;
		margin-bottom: $xxll;
	}
}
@mixin mv-xxxll-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxll;
		margin-bottom: $xxxll;
	}
}


// PADDING MIXINS
@mixin padding-0 {
	padding: 0;
}
@mixin padding-xxxs {
	padding: $xxxs;
}
@mixin padding-xxs {
	padding: $xxs;
}
@mixin padding-xs {
	padding: $xs;
}
@mixin padding-s {
	padding: $s;
}
@mixin padding-m {
	padding: $m;
}
@mixin padding-l {
	padding: $l;
}
@mixin padding-xl {
	padding: $xl;
}
@mixin padding-xxl {
	padding: $xxl;
}
@mixin padding-xxxl {
	padding: $xxxl;
}
@mixin padding-ll {
	padding: $ll;
}
@mixin padding-xll {
	padding: $xll;
}
@mixin padding-xxll {
	padding: $xxll;
}
@mixin padding-xxxll {
	padding: $xxxll;
}

@mixin pt-0 {
	padding-top: 0;
}
@mixin pt-xxxs {
	padding-top: $xxxs;
}
@mixin pt-xxs {
	padding-top: $xxs;
}
@mixin pt-xs {
	padding-top: $xs;
}
@mixin pt-s {
	padding-top: $s;
}
@mixin pt-m {
	padding-top: $m;
}
@mixin pt-l {
	padding-top: $l;
}
@mixin pt-xl {
	padding-top: $xl;
}
@mixin pt-xxl {
	padding-top: $xxl;
}
@mixin pt-xxxl {
	padding-top: $xxxl;
}
@mixin pt-ll {
	padding-top: $ll;
}
@mixin pt-xll {
	padding-top: $xll;
}
@mixin pt-xxll {
	padding-top: $xxll;
}
@mixin pt-xxxll {
	padding-top: $xxxll;
}

@mixin pb-0 {
	padding-bottom: 0;
}
@mixin pb-xxxs {
	padding-bottom: $xxxs;
}
@mixin pb-xxs {
	padding-bottom: $xxs;
}
@mixin pb-xs {
	padding-bottom: $xs;
}
@mixin pb-s {
	padding-bottom: $s;
}
@mixin pb-m {
	padding-bottom: $m;
}
@mixin pb-l {
	padding-bottom: $l;
}
@mixin pb-xl {
	padding-bottom: $xl;
}
@mixin pb-xxl {
	padding-bottom: $xxl;
}
@mixin pb-xxxl {
	padding-bottom: $xxxl;
}
@mixin pb-ll {
	padding-bottom: $ll;
}
@mixin pb-xll {
	padding-bottom: $xll;
}
@mixin pb-xxll {
	padding-bottom: $xxll;
}
@mixin pb-xxxll {
	padding-bottom: $xxxll;
}

@mixin pl-0 {
	padding-left: 0;
}
@mixin pl-xxxs {
	padding-left: $xxxs;
}
@mixin pl-xxs {
	padding-left: $xxs;
}
@mixin pl-xs {
	padding-left: $xs;
}
@mixin pl-s {
	padding-left: $s;
}
@mixin pl-m {
	padding-left: $m;
}
@mixin pl-l {
	padding-left: $l;
}
@mixin pl-xl {
	padding-left: $xl;
}
@mixin pl-xxl {
	padding-left: $xxl;
}
@mixin pl-xxxl {
	padding-left: $xxxl;
}
@mixin pl-ll {
	padding-left: $ll;
}
@mixin pl-xll {
	padding-left: $xll;
}
@mixin pl-xxll {
	padding-left: $xxll;
}
@mixin pl-xxxll {
	padding-left: $xxxll;
}

@mixin pr-0 {
	padding-right: 0;
}
@mixin pr-xxxs {
	padding-right: $xxxs;
}
@mixin pr-xxs {
	padding-right: $xxs;
}
@mixin pr-xs {
	padding-right: $xs;
}
@mixin pr-s {
	padding-right: $s;
}
@mixin pr-m {
	padding-right: $m;
}
@mixin pr-l {
	padding-right: $l;
}
@mixin pr-xl {
	padding-right: $xl;
}
@mixin pr-xxl {
	padding-right: $xxl;
}
@mixin pr-xxxl {
	padding-right: $xxxl;
}
@mixin pr-ll {
	padding-right: $ll;
}
@mixin pr-xll {
	padding-right: $xll;
}
@mixin pr-xxll {
	padding-right: $xxll;
}
@mixin pr-xxxll {
	padding-right: $xxxll;
}

@mixin ph-0 {
	padding-left: 0;
	padding-right: 0;
}
@mixin ph-xxxs {
	padding-left: $xxxs;
	padding-right: $xxxs;
}
@mixin ph-xxs {
	padding-left: $xxs;
	padding-right: $xxs;
}
@mixin ph-xs {
	padding-left: $xs;
	padding-right: $xs;
}
@mixin ph-s {
	padding-left: $s;
	padding-right: $s;
}
@mixin ph-m {
	padding-left: $m;
	padding-right: $m;
}
@mixin ph-l {
	padding-left: $l;
	padding-right: $l;
}
@mixin ph-xl {
	padding-left: $xl;
	padding-right: $xl;
}
@mixin ph-xxl {
	padding-left: $xxl;
	padding-right: $xxl;
}
@mixin ph-xxxl {
	padding-left: $xxxl;
	padding-right: $xxxl;
}
@mixin ph-ll {
	padding-left: $ll;
	padding-right: $ll;
}
@mixin ph-xll {
	padding-left: $xll;
	padding-right: $xll;
}
@mixin ph-xxll {
	padding-left: $xxll;
	padding-right: $xxll;
}
@mixin ph-xxxll {
	padding-left: $xxxll;
	padding-right: $xxxll;
}

@mixin pv-0 {
	padding-top: 0;
	padding-bottom: 0;
}
@mixin pv-xxxs {
	padding-top: $xxxs;
	padding-bottom: $xxxs;
}
@mixin pv-xxs {
	padding-top: $xxs;
	padding-bottom: $xxs;
}
@mixin pv-xs {
	padding-top: $xs;
	padding-bottom: $xs;
}
@mixin pv-s {
	padding-top: $s;
	padding-bottom: $s;
}
@mixin pv-m {
	padding-top: $m;
	padding-bottom: $m;
}
@mixin pv-l {
	padding-top: $l;
	padding-bottom: $l;
}
@mixin pv-xl {
	padding-top: $xl;
	padding-bottom: $xl;
}
@mixin pv-xxl {
	padding-top: $xxl;
	padding-bottom: $xxl;
}
@mixin pv-xxxl {
	padding-top: $xxxl;
	padding-bottom: $xxxl;
}
@mixin pv-ll {
	padding-top: $ll;
	padding-bottom: $ll;
}
@mixin pv-xll {
	padding-top: $xll;
	padding-bottom: $xll;
}
@mixin pv-xxll {
	padding-top: $xxll;
	padding-bottom: $xxll;
}
@mixin pv-xxxll {
	padding-top: $xxxll;
	padding-bottom: $xxxll;
}

@mixin padding-0-tablet {
	@include breakpoint (medium down) {
		padding: 0;
	}
}
@mixin padding-xxxs-tablet {
	@include breakpoint (medium down) {
		padding: $xxxs;
	}
}
@mixin padding-xxs-tablet {
	@include breakpoint (medium down) {
		padding: $xxs;
	}
}
@mixin padding-xs-tablet {
	@include breakpoint (medium down) {
		padding: $xs;
	}
}
@mixin padding-s-tablet {
	@include breakpoint (medium down) {
		padding: $s;
	}
}
@mixin padding-m-tablet {
	@include breakpoint (medium down) {
		padding: $m;
	}
}
@mixin padding-l-tablet {
	@include breakpoint (medium down) {
		padding: $l;
	}
}
@mixin padding-xl-tablet {
	@include breakpoint (medium down) {
		padding: $xl;
	}
}
@mixin padding-xxl-tablet {
	@include breakpoint (medium down) {
		padding: $xxl;
	}
}
@mixin padding-xxxl-tablet {
	@include breakpoint (medium down) {
		padding: $xxxl;
	}
}
@mixin padding-ll-tablet {
	@include breakpoint (medium down) {
		padding: $ll;
	}
}
@mixin padding-xll-tablet {
	@include breakpoint (medium down) {
		padding: $xll;
	}
}
@mixin padding-xxll-tablet {
	@include breakpoint (medium down) {
		padding: $xxll;
	}
}
@mixin padding-xxxll-tablet {
	@include breakpoint (medium down) {
		padding: $xxxll;
	}
}

@mixin pt-0-tablet {
	@include breakpoint (medium down) {
		padding-top: 0;
	}
}
@mixin pt-xxxs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxs;
	}
}
@mixin pt-xxs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxs;
	}
}
@mixin pt-xs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xs;
	}
}
@mixin pt-s-tablet {
	@include breakpoint (medium down) {
		padding-top: $s;
	}
}
@mixin pt-m-tablet {
	@include breakpoint (medium down) {
		padding-top: $m;
	}
}
@mixin pt-l-tablet {
	@include breakpoint (medium down) {
		padding-top: $l;
	}
}
@mixin pt-xl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xl;
	}
}
@mixin pt-xxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxl;
	}
}
@mixin pt-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxl;
	}
}
@mixin pt-ll-tablet {
	@include breakpoint (medium down) {
		padding-top: $ll;
	}
}
@mixin pt-xll-tablet {
	@include breakpoint (medium down) {
		padding-top: $xll;
	}
}
@mixin pt-xxll-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxll;
	}
}
@mixin pt-xxxll-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxll;
	}
}

@mixin pb-0-tablet {
	@include breakpoint (medium down) {
		padding-bottom: 0;
	}
}
@mixin pb-xxxs-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxxs;
	}
}
@mixin pb-xxs-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxs;
	}
}
@mixin pb-xs-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xs;
	}
}
@mixin pb-s-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $s;
	}
}
@mixin pb-m-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $m;
	}
}
@mixin pb-l-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $l;
	}
}
@mixin pb-xl-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xl;
	}
}
@mixin pb-xxl-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxl;
	}
}
@mixin pb-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxxl;
	}
}
@mixin pb-ll-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $ll;
	}
}
@mixin pb-xll-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xll;
	}
}
@mixin pb-xxll-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxll;
	}
}
@mixin pb-xxxll-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxxll;
	}
}

@mixin pl-0-tablet {
	@include breakpoint (medium down) {
		padding-left: 0;
	}
}
@mixin pl-xxxs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxs;
	}
}
@mixin pl-xxs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxs;
	}
}
@mixin pl-xs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xs;
	}
}
@mixin pl-s-tablet {
	@include breakpoint (medium down) {
		padding-left: $s;
	}
}
@mixin pl-m-tablet {
	@include breakpoint (medium down) {
		padding-left: $m;
	}
}
@mixin pl-l-tablet {
	@include breakpoint (medium down) {
		padding-left: $l;
	}
}
@mixin pl-xl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xl;
	}
}
@mixin pl-xxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxl;
	}
}
@mixin pl-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxl;
	}
}
@mixin pl-ll-tablet {
	@include breakpoint (medium down) {
		padding-left: $ll;
	}
}
@mixin pl-xll-tablet {
	@include breakpoint (medium down) {
		padding-left: $xll;
	}
}
@mixin pl-xxll-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxll;
	}
}
@mixin pl-xxxll-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxll;
	}
}

@mixin pr-0-tablet {
	@include breakpoint (medium down) {
		padding-right: 0;
	}
}
@mixin pr-xxxs-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxxs;
	}
}
@mixin pr-xxs-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxs;
	}
}
@mixin pr-xs-tablet {
	@include breakpoint (medium down) {
		padding-right: $xs;
	}
}
@mixin pr-s-tablet {
	@include breakpoint (medium down) {
		padding-right: $s;
	}
}
@mixin pr-m-tablet {
	@include breakpoint (medium down) {
		padding-right: $m;
	}
}
@mixin pr-l-tablet {
	@include breakpoint (medium down) {
		padding-right: $l;
	}
}
@mixin pr-xl-tablet {
	@include breakpoint (medium down) {
		padding-right: $xl;
	}
}
@mixin pr-xxl-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxl;
	}
}
@mixin pr-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxxl;
	}
}
@mixin pr-ll-tablet {
	@include breakpoint (medium down) {
		padding-right: $ll;
	}
}
@mixin pr-xll-tablet {
	@include breakpoint (medium down) {
		padding-right: $xll;
	}
}
@mixin pr-xxll-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxll;
	}
}
@mixin pr-xxxll-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxxll;
	}
}

@mixin ph-0-tablet {
	@include breakpoint (medium down) {
		padding-left: 0;
		padding-right: 0;
	}
}
@mixin ph-xxxs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxs;
		padding-right: $xxxs;
	}
}
@mixin ph-xxs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxs;
		padding-right: $xxs;
	}
}
@mixin ph-xs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xs;
		padding-right: $xs;
	}
}
@mixin ph-s-tablet {
	@include breakpoint (medium down) {
		padding-left: $s;
		padding-right: $s;
	}
}
@mixin ph-m-tablet {
	@include breakpoint (medium down) {
		padding-left: $m;
		padding-right: $m;
	}
}
@mixin ph-l-tablet {
	@include breakpoint (medium down) {
		padding-left: $l;
		padding-right: $l;
	}
}
@mixin ph-xl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xl;
		padding-right: $xl;
	}
}
@mixin ph-xxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxl;
		padding-right: $xxl;
	}
}
@mixin ph-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxl;
		padding-right: $xxxl;
	}
}
@mixin ph-ll-tablet {
	@include breakpoint (medium down) {
		padding-left: $ll;
		padding-right: $ll;
	}
}
@mixin ph-xll-tablet {
	@include breakpoint (medium down) {
		padding-left: $xll;
		padding-right: $xll;
	}
}
@mixin ph-xxll-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxll;
		padding-right: $xxll;
	}
}
@mixin ph-xxxll-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxll;
		padding-right: $xxxll;
	}
}

@mixin pv-0-tablet {
	@include breakpoint (medium down) {
		padding-top: 0;
		padding-bottom: 0;
	}
}
@mixin pv-xxxs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxs;
		padding-bottom: $xxxs;
	}
}
@mixin pv-xxs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxs;
		padding-bottom: $xxs;
	}
}
@mixin pv-xs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xs;
		padding-bottom: $xs;
	}
}
@mixin pv-s-tablet {
	@include breakpoint (medium down) {
		padding-top: $s;
		padding-bottom: $s;
	}
}
@mixin pv-m-tablet {
	@include breakpoint (medium down) {
		padding-top: $m;
		padding-bottom: $m;
	}
}
@mixin pv-l-tablet {
	@include breakpoint (medium down) {
		padding-top: $l;
		padding-bottom: $l;
	}
}
@mixin pv-xl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xl;
		padding-bottom: $xl;
	}
}
@mixin pv-xxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxl;
		padding-bottom: $xxl;
	}
}
@mixin pv-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxl;
		padding-bottom: $xxxl;
	}
}
@mixin pv-ll-tablet {
	@include breakpoint (medium down) {
		padding-top: $ll;
		padding-bottom: $ll;
	}
}
@mixin pv-xll-tablet {
	@include breakpoint (medium down) {
		padding-top: $xll;
		padding-bottom: $xll;
	}
}
@mixin pv-xxll-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxll;
		padding-bottom: $xxll;
	}
}
@mixin pv-xxxll-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxll;
		padding-bottom: $xxxll;
	}
}

@mixin padding-0-mobile {
	@include breakpoint (small down) {
		padding: 0;
	}
}
@mixin padding-xxxs-mobile {
	@include breakpoint (small down) {
		padding: $xxxs;
	}
}
@mixin padding-xxs-mobile {
	@include breakpoint (small down) {
		padding: $xxs;
	}
}
@mixin padding-xs-mobile {
	@include breakpoint (small down) {
		padding: $xs;
	}
}
@mixin padding-s-mobile {
	@include breakpoint (small down) {
		padding: $s;
	}
}
@mixin padding-m-mobile {
	@include breakpoint (small down) {
		padding: $m;
	}
}
@mixin padding-l-mobile {
	@include breakpoint (small down) {
		padding: $l;
	}
}
@mixin padding-xl-mobile {
	@include breakpoint (small down) {
		padding: $xl;
	}
}
@mixin padding-xxl-mobile {
	@include breakpoint (small down) {
		padding: $xxl;
	}
}
@mixin padding-xxxl-mobile {
	@include breakpoint (small down) {
		padding: $xxxl;
	}
}
@mixin padding-ll-mobile {
	@include breakpoint (small down) {
		padding: $ll;
	}
}
@mixin padding-xll-mobile {
	@include breakpoint (small down) {
		padding: $xll;
	}
}
@mixin padding-xxll-mobile {
	@include breakpoint (small down) {
		padding: $xxll;
	}
}
@mixin padding-xxxll-mobile {
	@include breakpoint (small down) {
		padding: $xxxll;
	}
}

@mixin pt-0-mobile {
	@include breakpoint (small down) {
		padding-top: 0;
	}
}
@mixin pt-xxxs-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxs;
	}
}
@mixin pt-xxs-mobile {
	@include breakpoint (small down) {
		padding-top: $xxs;
	}
}
@mixin pt-xs-mobile {
	@include breakpoint (small down) {
		padding-top: $xs;
	}
}
@mixin pt-s-mobile {
	@include breakpoint (small down) {
		padding-top: $s;
	}
}
@mixin pt-m-mobile {
	@include breakpoint (small down) {
		padding-top: $m;
	}
}
@mixin pt-l-mobile {
	@include breakpoint (small down) {
		padding-top: $l;
	}
}
@mixin pt-xl-mobile {
	@include breakpoint (small down) {
		padding-top: $xl;
	}
}
@mixin pt-xxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxl;
	}
}
@mixin pt-xxxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxl;
	}
}
@mixin pt-ll-mobile {
	@include breakpoint (small down) {
		padding-top: $ll;
	}
}
@mixin pt-xll-mobile {
	@include breakpoint (small down) {
		padding-top: $xll;
	}
}
@mixin pt-xxll-mobile {
	@include breakpoint (small down) {
		padding-top: $xxll;
	}
}
@mixin pt-xxxll-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxll;
	}
}

@mixin pb-0-mobile {
	@include breakpoint (small down) {
		padding-bottom: 0;
	}
}
@mixin pb-xxxs-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxxs;
	}
}
@mixin pb-xxs-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxs;
	}
}
@mixin pb-xs-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xs;
	}
}
@mixin pb-s-mobile {
	@include breakpoint (small down) {
		padding-bottom: $s;
	}
}
@mixin pb-m-mobile {
	@include breakpoint (small down) {
		padding-bottom: $m;
	}
}
@mixin pb-l-mobile {
	@include breakpoint (small down) {
		padding-bottom: $l;
	}
}
@mixin pb-xl-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xl;
	}
}
@mixin pb-xxl-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxl;
	}
}
@mixin pb-xxxl-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxxl;
	}
}
@mixin pb-ll-mobile {
	@include breakpoint (small down) {
		padding-bottom: $ll;
	}
}
@mixin pb-xll-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xll;
	}
}
@mixin pb-xxll-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxll;
	}
}
@mixin pb-xxxll-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxxll;
	}
}

@mixin pl-0-mobile {
	@include breakpoint (small down) {
		padding-left: 0;
	}
}
@mixin pl-xxxs-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxs;
	}
}
@mixin pl-xxs-mobile {
	@include breakpoint (small down) {
		padding-left: $xxs;
	}
}
@mixin pl-xs-mobile {
	@include breakpoint (small down) {
		padding-left: $xs;
	}
}
@mixin pl-s-mobile {
	@include breakpoint (small down) {
		padding-left: $s;
	}
}
@mixin pl-m-mobile {
	@include breakpoint (small down) {
		padding-left: $m;
	}
}
@mixin pl-l-mobile {
	@include breakpoint (small down) {
		padding-left: $l;
	}
}
@mixin pl-xl-mobile {
	@include breakpoint (small down) {
		padding-left: $xl;
	}
}
@mixin pl-xxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxl;
	}
}
@mixin pl-xxxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxl;
	}
}
@mixin pl-ll-mobile {
	@include breakpoint (small down) {
		padding-left: $ll;
	}
}
@mixin pl-xll-mobile {
	@include breakpoint (small down) {
		padding-left: $xll;
	}
}
@mixin pl-xxll-mobile {
	@include breakpoint (small down) {
		padding-left: $xxll;
	}
}
@mixin pl-xxxll-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxll;
	}
}

@mixin pr-0-mobile {
	@include breakpoint (small down) {
		padding-right: 0;
	}
}
@mixin pr-xxxs-mobile {
	@include breakpoint (small down) {
		padding-right: $xxxs;
	}
}
@mixin pr-xxs-mobile {
	@include breakpoint (small down) {
		padding-right: $xxs;
	}
}
@mixin pr-xs-mobile {
	@include breakpoint (small down) {
		padding-right: $xs;
	}
}
@mixin pr-s-mobile {
	@include breakpoint (small down) {
		padding-right: $s;
	}
}
@mixin pr-m-mobile {
	@include breakpoint (small down) {
		padding-right: $m;
	}
}
@mixin pr-l-mobile {
	@include breakpoint (small down) {
		padding-right: $l;
	}
}
@mixin pr-xl-mobile {
	@include breakpoint (small down) {
		padding-right: $xl;
	}
}
@mixin pr-xxl-mobile {
	@include breakpoint (small down) {
		padding-right: $xxl;
	}
}
@mixin pr-xxxl-mobile {
	@include breakpoint (small down) {
		padding-right: $xxxl;
	}
}
@mixin pr-ll-mobile {
	@include breakpoint (small down) {
		padding-right: $ll;
	}
}
@mixin pr-xll-mobile {
	@include breakpoint (small down) {
		padding-right: $xll;
	}
}
@mixin pr-xxll-mobile {
	@include breakpoint (small down) {
		padding-right: $xxll;
	}
}
@mixin pr-xxxll-mobile {
	@include breakpoint (small down) {
		padding-right: $xxxll;
	}
}

@mixin ph-0-mobile {
	@include breakpoint (small down) {
		padding-left: 0;
		padding-right: 0;
	}
}
@mixin ph-xxxs-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxs;
		padding-right: $xxxs;
	}
}
@mixin ph-xxs-mobile {
	@include breakpoint (small down) {
		padding-left: $xxs;
		padding-right: $xxs;
	}
}
@mixin ph-xs-mobile {
	@include breakpoint (small down) {
		padding-left: $xs;
		padding-right: $xs;
	}
}
@mixin ph-s-mobile {
	@include breakpoint (small down) {
		padding-left: $s;
		padding-right: $s;
	}
}
@mixin ph-m-mobile {
	@include breakpoint (small down) {
		padding-left: $m;
		padding-right: $m;
	}
}
@mixin ph-l-mobile {
	@include breakpoint (small down) {
		padding-left: $l;
		padding-right: $l;
	}
}
@mixin ph-xl-mobile {
	@include breakpoint (small down) {
		padding-left: $xl;
		padding-right: $xl;
	}
}
@mixin ph-xxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxl;
		padding-right: $xxl;
	}
}
@mixin ph-xxxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxl;
		padding-right: $xxxl;
	}
}
@mixin ph-ll-mobile {
	@include breakpoint (small down) {
		padding-left: $ll;
		padding-right: $ll;
	}
}
@mixin ph-xll-mobile {
	@include breakpoint (small down) {
		padding-left: $xll;
		padding-right: $xll;
	}
}
@mixin ph-xxll-mobile {
	@include breakpoint (small down) {
		padding-left: $xxll;
		padding-right: $xxll;
	}
}
@mixin ph-xxxll-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxll;
		padding-right: $xxxll;
	}
}

@mixin pv-0-mobile {
	@include breakpoint (small down) {
		padding-top: 0;
		padding-bottom: 0;
	}
}
@mixin pv-xxxs-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxs;
		padding-bottom: $xxxs;
	}
}
@mixin pv-xxs-mobile {
	@include breakpoint (small down) {
		padding-top: $xxs;
		padding-bottom: $xxs;
	}
}
@mixin pv-xs-mobile {
	@include breakpoint (small down) {
		padding-top: $xs;
		padding-bottom: $xs;
	}
}
@mixin pv-s-mobile {
	@include breakpoint (small down) {
		padding-top: $s;
		padding-bottom: $s;
	}
}
@mixin pv-m-mobile {
	@include breakpoint (small down) {
		padding-top: $m;
		padding-bottom: $m;
	}
}
@mixin pv-l-mobile {
	@include breakpoint (small down) {
		padding-top: $l;
		padding-bottom: $l;
	}
}
@mixin pv-xl-mobile {
	@include breakpoint (small down) {
		padding-top: $xl;
		padding-bottom: $xl;
	}
}
@mixin pv-xxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxl;
		padding-bottom: $xxl;
	}
}
@mixin pv-xxxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxl;
		padding-bottom: $xxxl;
	}
}
@mixin pv-ll-mobile {
	@include breakpoint (small down) {
		padding-top: $ll;
		padding-bottom: $ll;
	}
}
@mixin pv-xll-mobile {
	@include breakpoint (small down) {
		padding-top: $xll;
		padding-bottom: $xll;
	}
}
@mixin pv-xxll-mobile {
	@include breakpoint (small down) {
		padding-top: $xxll;
		padding-bottom: $xxll;
	}
}
@mixin pv-xxxll-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxll;
		padding-bottom: $xxxll;
	}
}

