#wishlist-container {
    .remove-from-wishlist {
        display: flex;
        position: absolute;
        top: 16px;
        right: 32px;
        z-index: $menu-z - 1;

        button {
            cursor: pointer;
        }
    }

    .product-container {
        .wishlist-container {
            display: none;
        }
    }
}