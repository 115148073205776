#profile-container {
    .responsive-table {
        @include breakpoint (small down) {
            overflow-x: auto
        }
    }

    .table-orders {

        thead {
            border: none;
            background-color: $light-grey;

            tr th {
                @include upper;
                @include body-3;
                @include bold;
                @include txt-center;
            }
        }

        tbody {
            border: none;

            tr {
                background-color: transparent;
                border-top: 1px solid $grey-1;

                &:nth-last-child(2) {
                    border-bottom: 1px solid $grey-1;
                }

                &.order-row {
                    cursor: pointer;
                }

                &.order-row.open {
                    td .icon {
                        transform: rotate(90deg);
                    }
                }

                &.order-row:hover {
                    background-color: $light-grey;
                }

                &.order-controls {
                    display: none;
                }

                td {
                    @include body-2;
                    @include padding-xxs;
                    @include txt-center;

                    .icon {
                        transition: transform .2s ease-in-out;
                    }
                }
            }

            tr td .order-controls-row {
                margin-top: $xs;

                .btn-group {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    .btn:not(:last-of-type) {
                        margin-right: $xxs;
                    }
                }
            }

            tr td .my-orders {
                &:not(:first-child) {
                    margin-top: $l;
                }
                
                .data-row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    width: 100%;
                    border-top: 1px solid $line-color;
                    @include pv-xxxs;

                    &:first-of-type {
                        margin-top: $xxs;
                    }

                    &:last-of-type {
                        border-bottom: 1px solid $line-color;
                    }

                    > :first-child {
                        @include semibold;
                    }
                }
            }

            tr td .order-controls-return {
                margin-top: $s;

                tbody tr:last-child {
                    border-bottom: 1px solid $grey-1;
                }
            }

            tr td .description {
                @include txt-left;
                
                ul, ol {
                    margin-top: $xxs;
    
                    li {
                        position: relative;
                        left: $xs;
    
                        &:not(:first-of-type) {
                            margin-top: $xxxs;
                        }
                    }
                }
    
                ul li {
                    list-style: disc;
                }
    
                ol li {
                    list-style: decimal;
                }
            }
        }
    }
}

.profile-content {
  @include mt-m;
  @include breakpoint(small down) {
    padding-left: 0;
    @include mt-s;
  }

  hr {
    border: none;
    border-top: 1px solid $line-color;
    @include mt-s;
  }
}

.btn-download {
  padding: 4px 8px;
  font-size: 13px;
  letter-spacing: 0;
  margin: 2px;
}

.new-return-request {
  display: none;
}

.table-orders {
  min-width: 650px;
}

.responsive-table {
  overflow-x: auto;
}

// profile consent
.profile-privacy-row {
  h4 {
    color: $black;
    text-transform: uppercase;
    font-size: 14px;
    @include mb-xxs;
  }
}

.inline-checkbox {
  label {
    @include regular;
  }
}

.profile-privacy-col-inputs {
  display: flex;
  justify-content: space-between;
  @include breakpoint(small down) {
    flex-direction: column;
  }
}

.profile-nav {
    color: $black;
    background: $white;
    font-size: 14px;

    a, [type=submit] {
        cursor: pointer;
        color: $black;
        background: $white;
        border: none;
        transition: all 0.15s ease-in-out;
        display: block;
        text-align: left;
        letter-spacing: 0.1em;
        margin-bottom: 0;
        text-transform: uppercase;
        padding: 16px 8px;
        @include bold;

        &.active,
        &:hover {
            color: $white;
            background: $black;
        }
    }
}

ul.list-unstyled.profile-nav li.active {
  background-color: $second-color;
  color: $second-color;
  padding: 16px 8px;
}
input:disabled,
input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: $light-grey;
}

.profile-credit {
  .title {
    @include mt-xxs;
  }

  .row {
    @include mt-xxs;
  }
}

.tools-export {
  @include mt-l;
  @include mb-l;
}

.tools-export-title {
  @include bold;
}

.tools-export-description {
  @include regular;
}

.avaliable-every {
  @include regular;
}

.return-requests-container {
  h3 {
    @include mb-xs;
    @include bold;
  }

  .dl-horizontal {
    dt {
      @include semibold;
    }
    dd {
      @include regular;
    }

    dt:nth-of-type(even),
    dd:nth-of-type(even) {
      background-color: $light-grey;
    }
  }
}

#api-key-container {
  display: flex;
  width: fit-content;
  @include mv-xs;
  @include padding-xxs;
  @include border;
}

.key-copied-toast {
  position: fixed;
  z-index: $messagebox-z;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100px;
  background: $grey-2;
  color: $white;
  @include padding-xxs;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}