#subscription-container {
    .subscription {
        .description {
            ul, ol {
                margin-top: $xxs;

                li {
                    position: relative;
                    left: $xs;

                    &:not(:first-of-type) {
                        margin-top: $xxxs;
                    }
                }
            }

            ul li {
                list-style: disc;
            }

            ol li {
                list-style: decimal;
            }
        }
    }
}