.swiper-container {
    .swiper-button-next, .swiper-button-prev {
        color: $black;
    }

    .swiper-slide {
        &.product-img img {
            width: 100%;
        }
    }
}

.swiper-slide {
    padding-bottom: 1px; // bottom border is hidden sometimes without this
}

#brands-swiper {
    .swiper-wrapper {
        display: flex;
        align-items: center;
    }

    .swiper-button-prev::after, .swiper-button-next::after {
        font-size: 16px;
    }
}

.circle {
    border-radius:100%;
}



#catalog-swiper {
    .swiper-slide {
        .card-description-height {
            height: 264px;
        }

        .card-description-background {
            background-position: top;
            background-size: cover;
        }

        .card-description {
            display: flex;
            align-items: flex-end;
            .sub-row-link {
                border-radius: 0px 0px 32px 32px;
                .padding-xs {
                    .row-link {
                        width:100%;
                        margin:0px;
                        justify-content: space-around;
                    }
                }
            }
        }
    }

    .swiper-button-prev, .swiper-button-next {
        display: none;
    }
}

[id*=category-swiper] {
    .swiper-wrapper {
        @include pv-xxl;
    }
}

#product-images-swiper {
    .swiper-slide img {
        border: 1px solid $grey-1;
    }
}