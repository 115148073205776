// DESKTOP
.black {
	color: $black;
}
.grey-3 {
	color: $grey-3;
}
.grey-2 {
	color: $grey-2;
}
.grey-1 {
	color: $grey-1;
}
.light-grey {
	color: $light-grey;
}
.white {
	color: $white;
}
.first-color {
	color: $first-color;
}
.second-color {
	color: $second-color;
}
.third-color {
	color: $third-color;
}
.line-color {
	color: $line-color;
}
.danger {
	color: $danger;
}
.success {
	color: $success;
}

// TABLET
.black-tablet {
	@include breakpoint (medium down) {
		color: $black;
	}
}
.grey-3-tablet {
	@include breakpoint (medium down) {
		color: $grey-3;
	}
}
.grey-2-tablet {
	@include breakpoint (medium down) {
		color: $grey-2;
	}
}
.grey-1-tablet {
	@include breakpoint (medium down) {
		color: $grey-1;
	}
}
.light-grey-tablet {
	@include breakpoint (medium down) {
		color: $light-grey;
	}
}
.white-tablet {
	@include breakpoint (medium down) {
		color: $white;
	}
}
.first-color-tablet {
	@include breakpoint (medium down) {
		color: $first-color;
	}
}
.second-color-tablet {
	@include breakpoint (medium down) {
		color: $second-color;
	}
}
.third-color-tablet {
	@include breakpoint (medium down) {
		color: $third-color;
	}
}
.line-color-tablet {
	@include breakpoint (medium down) {
		color: $line-color;
	}
}
.danger-tablet {
	@include breakpoint (medium down) {
		color: $danger;
	}
}
.success-tablet {
	@include breakpoint (medium down) {
		color: $success;
	}
}

// MOBILE
.black-mobile {
	@include breakpoint (small down) {
		color: $black;
	}
}
.grey-3-mobile {
	@include breakpoint (small down) {
		color: $grey-3;
	}
}
.grey-2-mobile {
	@include breakpoint (small down) {
		color: $grey-2;
	}
}
.grey-1-mobile {
	@include breakpoint (small down) {
		color: $grey-1;
	}
}
.light-grey-mobile {
	@include breakpoint (small down) {
		color: $light-grey;
	}
}
.white-mobile {
	@include breakpoint (small down) {
		color: $white;
	}
}
.first-color-mobile {
	@include breakpoint (small down) {
		color: $first-color;
	}
}
.second-color-mobile {
	@include breakpoint (small down) {
		color: $second-color;
	}
}
.third-color-mobile {
	@include breakpoint (small down) {
		color: $third-color;
	}
}
.line-color-mobile {
	@include breakpoint (small down) {
		color: $line-color;
	}
}
.danger-mobile {
	@include breakpoint (small down) {
		color: $danger;
	}
}
.success-mobile {
	@include breakpoint (small down) {
		color: $success;
	}
}