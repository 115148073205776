.paginator {
    .pagination-container {
        .pagination {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            li a {
                display: inline-block;
                @include body-2;
                @include semibold;

                &:hover {
                    background-color: transparent !important;
                }
            }

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                border: 1px solid $black;
                @include mh-xxxs;

                &:not(:first-child):not(:last-child):not(.active):hover {
                    background-color: $grey-1;
                }
            }

            li.active {
                border-color: $first-color;
                background-color: $first-color;

                a {
                    color: $white;
                }
            }

            li:first-child, li:last-child {
                border: none;
            }

            @include breakpoint(small down) {
                li:nth-child(n+9):not(:last-child) {
                    display: none;
                }
                
                /*
                    Show next-page icon from catalog into mobile-version because common.ftl 
                    into macro pagination assign class "show-for-mobile" when paginator over 10
                */
                li:last-child{
                    display: block!important;
                }
            }
        }
    }
}