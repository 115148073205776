.blog-archive-banner {
  height: 24vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 30px;
  @include breakpoint(medium down) {
    height: 30vw;
  }
  @include breakpoint(small down) {
    height: auto;
  }

  &:after {
    @include breakpoint(small down) {
      content: "";
      position: absolute;
      background-color: rgba(255, 255, 255, 0.3);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
  }

  > div {
    max-width: 330px;
    text-align: center;
    margin-right: 35vw;
    @include breakpoint(small down) {
      margin: 25px auto 20px;
      padding: 0 $xs;
      z-index: 1;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 33px;
    @include breakpoint(medium down) {
      font-size: 26px;
      line-height: 28px;
    }
  }

  p {
    margin: 12px 0;
    @include breakpoint(medium down) {
      margin: 8px 0;
      font-size: 14px;
    }
  }
}

.blog-categories {
  background-color: $second-color;
  padding: 23px 30px;
  margin-bottom: 30px;

  &.about-us {
    text-align: center;

    .blog-categories-logo {
      max-width: 230px;
      margin-top: 10px;
    }

    p {
      font-size: $xs;
      margin: $xs 0;
    }

    .blog-categories-social a {
      margin: 8px 10px $xs;
      @include basic-transition;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &.categories {
    .category-link {
      display: block;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: 14px;
      padding: 8px 0 3px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: $second-color;
        @include basic-transition;
      }

      &:hover {
        &:after {
          height: 4px;
        }
      }
    }
  }

  &.subscribe {
    .medium-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.blog-post-summary-container {
  @include breakpoint(small down) {
    padding-bottom: 20px;
  }

  .blog-post-link {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .blog-post-image {
    height: 250px;
    overflow: hidden;
    @include breakpoint(small down) {
      height: 50vw;
    }

    div {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      height: inherit;
      transition: all 0.5s ease-in-out;
    }
  }
  .blog-post-text {
    @include mt-xxs;

    p {
      @include mt-xxs;
    }
    span {
      @include ml-xxs;
      text-transform: uppercase;
      color: $second-color;
      font-size: 14px;
      @include semibold;
    }
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
  }

  &:hover {
    .blog-post-image div {
      transform: scale(1.1);
    }
  }
}

.social-share {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  @include breakpoint(medium down) {
    bottom: 0;
    transform: none;
    top: auto;
  }

  .social-button {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.facebook {
      background-color: #2572cc;
    }
    
    &.twitter {
      background-color: #27baf9;
    }

    &:hover {
      opacity: 0.7;
    }

    img {
      width: 23px;
    }
  }
}

.blog-post-wrapper {
  padding-top: 20px;

  .blog-post-cover {
    float: left;
    padding-right: 20px;
    width: 8$xs;
    @media only screen and (max-width: 1150px) {
      width: 700px;
    }
    @include breakpoint(medium down) {
      width: 100%;
      float: none;
      margin-bottom: $xs;
      padding: 0;
    }
  }

  .blog-post-title {
    font-size: 26px;
    line-height: 30px;
    margin-top: 3px;
  }
}

.related-post-title {
  padding: 24px 0;
  border-top: 1px solid $line-color;
  @include mt-l;
}

.blog-link {
  img {
    @include basic-transition;
    transform: rotate(180deg);
    width: 35px;
    position: relative;
    top: -1px;
    margin-right: 7px;
  }

  &:hover {
    img {
      transform: rotate(180deg) translateX(3px);
    }
  }
}

// archive
.mansory-gallery-blog {
  img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.blog-archive-right-block {
  background-color: #ffffff7a;
  position: absolute;
  left: 40px;
  bottom: 32px;
  width: 80%;
  padding: 16px;
}

.title-blog-archvie {
  font-size: 30px;
  line-height: 40px;
  @include bold;

  @include breakpoint(medium down) {
    font-size: 25px;
    line-height: 32px;
  }

  &.main-title {
    font-size: 53px;
  }
}
.box-archive-page {
  height: 350px;
}
.box-archive-post {
  height: 440px;
}

// single page blog

.blog-post-date {
  font-size: 14px;
  @include mt-xxs;
}

.title-article-blog {
  margin: 32px 0px;
  font-size: 40px;
  line-height: 48px;
  @include breakpoint(medium down) {
    font-size: 35px;
    line-height: 40px;
  }
}

.centered-padding-paragraph {
  padding: 0 8vw;
}

.txt-cta {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}

.image-cover-blog {
  background-repeat: no-repeat;
  background-size: cover;
}

.blog-left-box {
  @include breakpoint(medium down) {
    @include mt-xs;
  }
}

.blog-images-container {
  display: flex;
  align-items: center;
}

.blog-images-top-margin {
  @include breakpoint(small down) {
    @include mt-m;
  }
}
