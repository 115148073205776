#subscriptions-container {
    .border-line-grey {
        border: 1px solid $grey-1;
    }

    .dropshipping-banner {
        $header-height: 70px;
        $top-banner-height: 40px;
        height: calc(100vh - #{$header-height} - #{$top-banner-height});
        position: relative;
        z-index: 1;
        @include breakpoint (medium down) {
            height: 100vh;
            @include mb-0;
        }
        
        .banner-image {
            position: absolute;
            z-index: -1;
            height: 100vh;
            width: 37vw;
            
            @media screen and (min-width: 63.9375em) {
                width: 40vw;
            }

            @media screen and (min-width: 64em) and (max-width: 65em) {
                width: 70vw;
            }
            @include breakpoint (medium down) {
                height: 100vh;
                width: 100vw;
            }

            @media screen and #{breakpoint(medium down)} and #{breakpoint(landscape)} {
                width: 50vw;
            }
            
        }
    }

    #dropshipping-tabs {
        .tabs {
            display: flex;
            &.border-radius-top {
                border-radius: 32px 32px 0 0
            }
        }
        .tabs-title {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            @include padding-s;
            @include breakpoint(small down){
                @include padding-xxs;
            }

            color: $white;
            
            &.selected {
                background-color: $first-color;
                @include bold;
            }

            @include breakpoint(small down) {
                width: 100%
            }

            &:nth-child(1){
                &.border-radius-top-left {
                    border-radius:32px 0 0 0;
                }
            }

            &:nth-last-child(1){
                &.border-radius-top-right {
                    border-radius:0 32px 0 0;
                }
            }
        }


        .tab-content {
            @include border-no-top;
            &.border-radius-bottom {
                border-radius: 0 0 32px 32px;
            }
        }

        .dropshipping-plugin-title {
            font-size: 22px;
            line-height: 32px;
            margin-bottom: 16px;
            margin-top: 16px;
            text-align: center;
            @include bold;
        }
    }

    #subscriptions-list .subscription {
        @include border;
        @include pv-m;
        @include ph-xs;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: $m;

        
        .description {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .price-container {
                font-size: 40px;
                line-height: 56px;
            }
        }
    }

    .plugin-list li {
        list-style: disc;
        margin-left: 16px;
    }

    .background-what-we-offer {
        background: linear-gradient(50deg, rgba(158, 158, 158, 0.2) 54%, rgba(0, 0, 0, 0) 65%), linear-gradient(66deg, $white 59%, $first-color 59%);
        @include breakpoint (medium down){
            background:$light-grey;

        }

        @include breakpoint (small down){
            background:$light-grey;

        }

    }

    .how-it-works {
        overflow-x: hidden;
        
        .how-it-works-step {
            position: relative;

            .text {
                position: absolute;
                bottom: 0;
                width: calc(100% - 68px - #{$xs} * 2);

                @include body-3-mobile;
            }

            .arrow {
                $arrow-size: 68px;
                position: absolute;
                top: 60%;
                right: calc(-#{$arrow-size} / 2);

                .background-image {
                    height:$arrow-size;
                    width: $arrow-size;
                }
            }

            .image {
                width:224px;
                height:264px;
            }
        }

        .how-it-works-step:first-of-type {
            $size: 56px;
        }

        .how-it-works-step:nth-last-of-type(2) {
            z-index: 1;
            $size: calc(56px / 1.414);

            @include breakpoint (medium down) {

                .text::before {
                    right: unset;
                    left: calc(-#{$size} / 2);
                }
            }
        }

        
        $arrow-size-small: 32px;
        @include breakpoint (medium down) {

            .how-it-works-step .arrow {
                right: calc(-#{$arrow-size-small} / 2);
                
                .background-image {
                    width: $arrow-size-small !important;
                    height: $arrow-size-small !important;
                }
            }

            .how-it-works-step:nth-child(2) .arrow {
                transform: rotate(90deg);
                right: calc(50% - #{$arrow-size-small}/2);
                top: unset;
                bottom: calc(-#{$m} - #{$arrow-size-small}/2);
            }
            
            .how-it-works-step:nth-child(3) .arrow {
                transform: rotate(180deg);
                right: unset;
                left: calc(-#{$arrow-size-small}/2);
            }
            
            .how-it-works-step:nth-child(3) {
                margin-top: $xxxl;
                transform: translateX(100%);
            }
            
            .how-it-works-step:nth-child(4) {
                margin-top: $xxxl;
                transform: translateX(-100%);
            }
        }

        @include breakpoint (small down) {
            .how-it-works-step {
                .image {
                    width: 133px !important;
                    height: 156px !important;
                }

                .text {
                    width: calc(100% - #{$arrow-size-small} - #{$xxs});
                }

                .arrow {
                    top: 50%;
                }
            }

            .how-it-works-step:first-of-type {
                $size: 32px;
                
                .text::before {
                    left: calc(-#{$size} / 2);
                    width: calc(#{$size} / 2);
                    height: $size;
                }
                
                .text::after {
                    width: $size;
                    height: $size;
                    left: -$size;
                }
            }
    
            .how-it-works-step:nth-last-of-type(2) {
                $size: calc(32px / 1.414);

                .text::before {
                    width: $size;
                    height: $size;
                    left: calc(-#{$size} / 2);
                    top: calc((32px - #{$size}) / 2);
                }
            }
        }
    }

    .what-we-offer .item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        &:not(:first-child) {
            margin-top: $s;
        }

        .index {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: $m;
            min-width: $xl;
            min-height: $xl;
            background-color: $white;
            border: 1px solid $first-color;
            border-radius: 50%;
        }
    }

    .bg-first-color-opacity-30{
        background-color:rgba($first-color,.30);
    }

    .bg-first-color-opacity-10{
        background-color:rgba($first-color,.10);
    }

    .dropshipping-works{
        .item{
            display: flex;
            align-items: flex-start; 

            &:not(:last-child){
                .content{
                    margin-bottom: $xs;
                }

                .index-container{
                    &::after{
                        content: "";
                        border-right: 1px solid $first-color;
                        position: absolute;
                        top:$xxl;
                        bottom:$xxs;
                        right: 50%;
                    }
                }
            }

            .index-container{
                position: relative;
                align-self: stretch;
                margin-right: $m;
                .index{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: $xl;
                    min-height: $xl;
                    background-color: $white;
                    border: 1px solid $first-color;
                    border-radius: 50%;
                }
            }
        }
    }  
}









