#cart-container, #checkout-container {
    @include pv-s;
    background-color: $light-grey;

    .cart-details, .delivery-details {
        background-color: $white;
        padding: $s;
        @include breakpoint(small down) { 
            padding: $xxs;
        }

    }

    .cart-message-container {
        @include breakpoint(medium down) {
            display: inline-block;
            @include mb-xs;
        }
    }
}

.image-column {
    border: 1px solid $grey-1;
}

.detail-column-summary {
    @include breakpoint(small down) {
        display: inline-block;
        text-align: center;
    }
}