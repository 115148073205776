dialog {
    border: none;
    margin: auto;
    min-width: 30vw;
    max-width: 30vw;
    max-height: 90vw;

    @include breakpoint (medium only) {
        max-width: 50vw;
    }
    @include breakpoint (small down) {
        max-width: 90vw;
    }

    > div {
        position: relative;
        @include padding-l;
        .text {
            font-size: 18px;
            line-height: 32px;
        }

        .close-button {
            cursor: pointer;
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }

    &::backdrop {
        background-color: rgba(0, 0, 0, 0.7);
    }
}