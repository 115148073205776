.grid-table {
    display: grid;
    grid-template-columns: repeat(var(--cols), 1fr);
    border-top: 1px solid $black;
    border-right: 1px solid $black;

    .caption {
        position: absolute;
        top: 100%;
        left: 0;
    }

    .cell {
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid $black;
        border-bottom: 1px solid $black;

        &.header {
            background-color: $light-grey;
            @include bold;
        }

        &.multi-row {
            grid-row-end: span var(--rowspan);
        }
        &.multi-col {
            grid-row-end: span var(--colspan);
        }
    }
}