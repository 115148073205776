#catalog-container {
    &.loading {
        #catalog-wrapper {
            pointer-events: none;
            opacity: .5;
        }
    }

    #filters-toggle {
        @include breakpoint (medium down) {
            background-color: $light-grey;
            border: 1px solid $line-color;
            @include semibold;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .icon {
            transition: transform .2s;
        }

        &.open .icon {
            transform: rotate(90deg);
        }
    }

    #filters-toggle-mobile {
        display: flex;
        margin-right: auto;
        border-radius: $radius-medium;
        @include padding-xxxs;

        &:active {
            background-color: $light-grey;
        }
    }

    #filters {
        transition: transform .4s ease-in-out;

        @include breakpoint (large up) {
            max-height: 0;
            overflow-y: hidden;
    
            &.open {
                max-height: none;
            }
        }

        @include breakpoint (medium down) {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            overflow: auto;
            padding-top: $xll + $l;
            transform: translateX(-100%);
            background: $light-grey;
            z-index: $overlay-z - 1;

            &.open {
                transform: translateX(0);
            }

            .mobile-overlay {
                position: absolute;
                left: 0;
                right: 0;
                top: $xxxl;
                bottom: 0;
                background-color: $white;
                border-top-left-radius: 24px;
                border-top-right-radius: 24px;
                z-index: -1;
                transition: opacity .4s ease-in-out;
            }

            .close-filters {
                cursor: pointer;
                position: absolute;
                top: $s;
                right: $s;
            }
        }

        .dropdown {
            @include breakpoint (large up) {
                &:not(:last-child) {
                    margin-bottom: $xxxs;
                }
    
                .dropdown-toggle {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 40px;
                    @include ph-xs;
                    background-color: $light-grey;
        
                    .dropdown-label {
                        @include body-2;
                        @include bold;
                        text-transform: uppercase;
                    }
        
                    .dropdown-icon {
                        transition: transform .2s;
                    }
                }
                
                .dropdown-toggle.open {
                    background-color: transparent;
                    border-top: 1px solid $line-color;
                    
                    .dropdown-label {
                        @include bold;
                    }
                    
                    .dropdown-icon {
                        transform: rotate3d(1, 0, 0, 180deg);
                    }
                }
        
                .dropdown-content {
                    @include pv-xxs;
                    @include ph-xs;
                    max-height: 360px;
                    overflow-y: auto;

                    .filters-list {
                        li.see-all {
                            &.clickable {
                                @include pt-xs;
                                @include pb-xxs;
                            }
                        }
                    }
    
                    .filter .checkbox-container label {
                        @include body-2;
                        margin-bottom: calc($xxs / 2);
                    }
                   
                }
            }

            @include breakpoint (medium down) {
                .dropdown-toggle {
                    background-color: $white;
                    border-bottom: 1px solid $line-color;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    width: 100%;
                    @include pv-xs;
                    @include ph-xxs;
        
                    .dropdown-label {
                        @include body-2;
                        @include bold;
                        text-transform: uppercase;
                    }
        
                    .dropdown-icon {
                        transition: transform .2s;
                    }
                }
                
                .dropdown-toggle.open {
                    .dropdown-label {
                        color: $second-color;
                    }
                    
                    .dropdown-icon {
                        transform: rotate3d(1, 0, 0, 180deg);
                    }
                }

                .dropdown-content {
                    background-color: $white;

                    .filters-list {
                        border-left: 3px solid $second-color;

                        li.see-all {
                            border-bottom: 1px solid $line-color;
                            
                            &.clickable {
                                @include pv-xs;
                                @include ph-xxs;
                            }
                        }

                        .filter {
                            border-bottom: 1px solid $line-color;

                            > a {
                                display: block;
                            }

                            .checkbox-container {
                                label::before, label::after {
                                    display: none;
                                }
        
                                label {
                                    width: 100%;
                                    margin: 0;
                                    @include body-2;
                                    @include pv-xs;
                                    @include ph-xxs;
                                }

                                input:checked + label {
                                    color: $second-color;
                                    background-color: $light-grey;
                                    @include bold;
                                }
                            }

                            &.sort {
                                .checkbox-container input:checked + label {
                                    pointer-events: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .current-filter {
        display: flex;
        align-items: baseline;
        @include body-2;
        padding: 0 $xxs;
        background-color: $light-grey;
        border: 1px solid $grey-1;
        margin-right: $xxs;

        &:nth-last-child(2) {
            margin-right: $xs;
        }

        .icon {
            margin-left: $xs;
            transform: rotate(45deg);
        }

        &.remove-all {
            border: none;
            background-color: transparent;
            text-decoration: underline;
            margin-right: 0;
            padding: 0;
        }

        @include breakpoint (medium down) {
            @include mb-xxs;
        }
    }

    #sort-options {
        position: relative;
        margin-right: $s;

        .sort-btn {
            cursor: pointer;
            position: relative;
            padding: $xxxs $xxs;
            border: 1px solid transparent;
            border-bottom: none;
            border-radius: 4px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            transition: border-color 0s linear .4s;

            &::after {
                content: '';
                opacity: 0;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                border-bottom: 1px solid $white;
                z-index: $menu-z + 1;
                transition: opacity 0s linear .4s;
            }

            &.open {
                border-color: $line-color;
                transition: border-color 0s linear 0s;

                .icon {
                    transform: rotate3d(1, 0, 0, 180deg);
                }

                &::after {
                    opacity: 1;
                    transition: opacity 0s linear 0s;
                }
            }
        }

        .sort-dropdown {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            min-width: 100%;
            z-index: $menu-z;
            background-color: $white;
            border: 1px solid $line-color;
            border-radius: 4px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            li {
                padding: $xxxs $xxs;

                a {
                    display: inline-block;
                    width: 100%;
                    white-space: nowrap;
                }
                
                &:hover, &:active {
                    background-color: $light-grey;
                }
            }
        }
    }

    #layout-options {
        display: flex;

        .radio-layout {
            display: flex;

            &:first-child {
                margin-right: $xxs;
            }
        }

        [type=radio] {
            display: none;
        }

        [type=radio] + label {
            margin: 0;
            
            .enabled {
                display: none;
            }
            .disabled {
                display: block;
            }
        }

        [type=radio]:checked + label {
            .enabled {
                display: block;
            }
            .disabled {
                display: none;
            }
        }
    }
}
