.breadcrumb {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    .breadcrumb-piece-container {
        .breadcrumb-piece {
            @include body-3;
            white-space: nowrap;
        }

        &:last-child {
            pointer-events: none;
        }
    }
}