.show-on-mobile-menu-open {
    @include breakpoint (large up) {
        display: none !important;
    }
    @include breakpoint (medium down) {
        &:not(.show) {
            display: none !important;
        }
    }
}

#currency-dropdown, #language-dropdown {
    cursor: pointer;
}

#currency-dropdown,#language-dropdown {
    cursor:pointer;
}

.dropdown-column {
    #currency-dropdown {
        .dropdown-content {
            width:250px ;
        }        
        
    }
    
}

.clickable {
    span:last-child {
        color: $grey-3;
        font-weight: 600;
    }
}

.box-shadow {
    box-shadow: 0px 0px 9px 1px $light-grey;
}

#mobile-menu-back {
    visibility: hidden;
    
    @include breakpoint(medium down) {
        &.active {
            visibility: visible;
        }
    }
}

.submenu-recommended {
    color: $second-color;
    font-weight: 600;
    text-transform: uppercase;
}

#menu {
    @include breakpoint(large up) {
        display: flex;
        align-items: center;
        justify-content: center;

        $menu-border-width: 1px;

        .main-menu {
            border-bottom: $menu-border-width solid $grey-1;
        }

        .menu-item[data-level="1"] {
            &:not(:last-child) {
                margin-right: $s;
            }
            &.mobile {
                display: none;
            }

            > a, > span {
                display: block;
                border-bottom: 5px solid transparent;
                @include bold;
                @include pv-xs;
            }

            &:hover, &.selected {
                > a, > span {
                    color: $second-color;
                    @include bold;
                    border-color: $second-color;
                    font-weight: 700;
                }

                > .submenu {
                    display: flex;
                    background-color: $white;
                    padding-left: calc((100vw - 80rem) / 2);
                    padding-right: calc((100vw - 80rem) / 2);
                    flex-wrap: wrap;
                    flex-direction: column;
                }
            }

            > .submenu {
                height: 500px;
                background-color: $white;
                padding: $xs;
                display: none;
                position: absolute;
                top: calc(100% - #{$menu-border-width});
                left: 0;
                right: 0;
                border-top: 2px solid $second-color;
                border-bottom: 2px solid $line-color;

                .menu-item[data-level="2"] {
                    flex-grow: 1;
                    margin-right: $xs;
                    margin-left: $xs;
                    margin-bottom: $xs;
                    
                    > a, > span {
                        color: $second-color;
                        @include semibold;
                        text-transform: uppercase;
                    }

                    > .submenu {
                        > a, > span {
                            color: $second-color;
                            @include semibold;
                            text-transform: uppercase;
                        }

                        .submenu-entries {
                            li {
                                @include pb-xxs;
                            }
                        }
                        .menu-item[data-level="3"] {
                            &.see-all {
                                a {
                                    @include bold;
                                }
                            }

                            > a, > span {
                                @include body-2;
                            }
                        }
                    }
                }
            }

            > .menu-item {
                &.image {
                    width: 408px;
                    height: 331px;
                    @include breakpoint(medium down) {
                        display: none;
                    }
                }
            }
        }
    }

    @include breakpoint(medium down) {
        $mobile-menu-width: 100vw;
        position: fixed;
        width: $mobile-menu-width;
        top: var(--header-height, 0px);
        bottom: 0;
        transform: translateX(-#{$mobile-menu-width});
        transition: transform 0.4s ease-in-out, background-color 0.4s;
        display: block;
        height: calc(100vh - var(--header-height, 0px));
        overflow-y: auto;
        background-color: $white;



        &.open {

            transform: translateX(0);
            padding-bottom:calc(var(--header-height,0px) + $s);


            .menu-item[data-level="1"].mobile.header {
                opacity: 1;
            }
        }

        .menu-item.image {
            display: none;
        }

        .menu-item[data-level="1"] {
            display: block;
            position: relative;
            width: 100%;

            &.open {
                border-bottom: 1px solid $line-color;
                //max-height: calc(100vh - var(--header-height, 0px));
                overflow-y: hidden;
                margin-bottom: calc(var(--header-height)/2);

                > a, > span {
                    pointer-events: none;
                    @include semibold;
                    color: $second-color;
                    
                    &::after {
                        display: none;
                    }
                }
            }
            > span a {
                position: relative;
                display: flex;
                width: 100%;
                @include body-1;
                @include semibold;
                text-transform: capitalize;
            }

            > a, > span {
                position: relative;
                display: flex;
                width: 100%;
                border-bottom: 1px solid $line-color;
                @include pv-xs;
                @include ph-xxs;
                @include body-1;
                @include semibold;
                text-transform: capitalize;

                &:hover {
                    font-weight: 700;
                    color: $second-color;
                }
        
                &:active {
                    background-color: $light-grey;
                    font-weight: 700;
                    color: $second-color;

                }
            }

            &.has-submenu {
                > a, > span {
                    &::after {
                        position: absolute;
                        right: $xxs;
                        top: calc(50% - 7px);
                        transition: transform 0.2s;
                        @include pseudo-element-image($icons + "/general-icons/arrow-right-black.svg", 7px, 14px);
                        content: "";
                    }
                }
            }

            .submenu {
                $single-element-height: 24px + 16px * 2 + 1px;
                display: none;
                //max-height: calc(100vh - var(--header-height, 0px) - #{$single-element-height});
                overflow-y: auto;
            }
            
            &.mobile:not(.header) {
                > a::before,
                > span::before {
                    content: "";
                    display: inline-block;
                    
                }
                &.cart {
                    > a::before,
                    > span::before {
                        @include pseudo-element-image($icons + "/theme-icons/cart.svg", 24px, 24px);
                    }
                }
                &.profile {
                    > a::before,
                    > span::before {
                        @include pseudo-element-image($icons + "/theme-icons/profile-blue.svg", 24px, 24px);
                        @include mr-xxs;
                    }
                }
                &.wishlist {
                    > a::before,
                    > span::before {
                        @include pseudo-element-image($icons + "/theme-icons/whishlist.svg", 24px, 24px);
                    }
                }
            }

            &.mobile.header {
                background-color: $light-grey;
                padding-bottom: $xs;
                border: none;
                transition: opacity .4s linear;
                opacity: 0;

                > a, > span {
                    display: none;
                }

                .submenu {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    @include padding-xs;

                    .menu-item {
                        > a, > span {
                            border-bottom: none;
                            @include padding-0;
                        }
                    }

                    .menu-item.button {
                        background-color: $first-color;
                        border-radius: 20px;
                        padding: $xxs $xs;

                        > a, > span {
                            @include semibold;
                            color: $white;
                        }
                    }

                    .menu-item.link {
                        > a, > span {
                            @include semibold;
                            @include upper;
                            @include body-2;
                        }
                    }
                }
            }
        }

        .menu-item[data-level="2"] {
            &.open {
                > a, > span {
                    @include semibold;
                    color: $second-color;
                    &::after {
                        transform: rotate3d(1, 0, 0, 180deg);
                    }
                }
            }

            > a, > span {
                position: relative;
                display: block;
                width: 100%;
                @include pv-xs;
                @include ph-xxs;
                @include body-2;
                @include semibold;
                text-transform: capitalize;

                &:active {
                    background-color: $light-grey;
                }
            }

            &:not(:last-child) {
                > a, > span {
                    border-bottom: 1px solid $line-color;
                }
            }


            &.has-submenu {
                > a, > span {
                    &::after {
                        position: absolute;
                        right: $xxs;
                        top: calc(50% - 3.5px);
                        transition: transform 0.2s;
                        @include pseudo-element-image($icons + "/general-icons/arrow-down-black.svg", 14px, 7px);
                        content: "";
                    }
                }
            }

            .submenu {
                display: none;
                border-left: 4px solid $second-color;
            }
        }

        .menu-item[data-level="3"] {
            &.see-all {
                a {
                   @include bold; 
                }
            }

            > a, > span {
                position: relative;
                display: block;
                width: 100%;
                border-bottom: 1px solid $line-color;
                @include pv-xs;
                @include ph-xxs;
                @include body-2;
                @include semibold;
                text-transform: capitalize;

                &:active {
                    background-color: $light-grey;
                }
            }
        }
    }
}
