#designers-container {
  @include pt-xs;
  @include pb-l;

  .title {
    @include mt-xs;
    @include mb-l;
    @include bold;
  }

  a.card:hover {
    background-color: $second-color;
    border-radius: 50%;
    color: $white;
  }
}

.designer-column {
  @include mb-m;

  .column-title {
    display: flex;
    align-items: center;
    @include regular;

    span {
      @include ml-xxs;
      @include mr-xxs;
    }
  }

  .column-title::before,
  .column-title::after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: $black;
  }
}

.designer-list {
  li {
    display: block;
    line-height: 32px;
    @include regular;
  }
}

.designer-letters {
  justify-content: space-around;
  padding: 24px 0;
}

.sticky-letters {
  position: sticky;
  top: 57px;
  background: $white;
  @include breakpoint(medium down) {
    top: 102px;
  }
  @include breakpoint(small down) {
    top: 106px;
  }
}

.scrolling-wrapper {
  @media screen and (max-width: 63.9em) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
  }
}

.card {
  min-width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}

.letter-box {
  @media screen and (max-width: 63.9em) {
    width: 100%;
    padding: 24px 24px 0 24px;
  }
}
