#checkout-wrapper {
    h2 {
        @include bold;
        @include breakpoint(small down) {
            text-align: center;
            @include mb-xxs;
        }
    }
}

.checkout-step-title {
    border: 1px solid $grey-1;
    background-color: $white;
    color: $second-color;
    padding: $xxs $xs $xxs;
    text-transform: uppercase;
    margin: $xxs 0;
    @include body-2;
    @include semibold;
}

.checkout-step-title-next {
    border: 1px solid $grey-1;
    padding: $xxs $xs $xxs;
    text-transform: uppercase;
    margin: $xxs 0;
    @include body-2;
    opacity: 0.3;
}

.checkout-step-title-done {
    cursor: pointer;
    border: 1px solid $grey-1;
    padding: $xxs $xs $xxs;
    text-transform: uppercase;
    margin: $xxs 0;
    @include body-2;
    background-color: $light-grey;
    color: $grey-3;

    &:hover,
    &:active {
        background-color: $light-grey;
        color: $second-color;
    }
}

.cart-summary-container {
    background-color: $light-grey;
    padding: 0;

    .checkout-step-title {
        border-color: $second-color;
    }

    .cart-summary {
        padding: 0 $s $xs;
        font-size: 14px;
    }
}

#billing-form-btn {
    @include breakpoint(small down) {
        margin-bottom: 10px;
    }
}

.additional-fee {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    margin: -7px 0 12px;
}

.container-paypal-additional-cost {
    display: flex;
    align-items: center;
    & span {
        font-size: 14px;
        @include regular;
    }
}

.order-zero-amount {
    @include mb-xxs;
    @include semibold;
}

.btn.checkout {
    width: 100%;
    padding: 16px;
    margin-bottom: 0;
    @include mt-xxs;
}

#paypalBraintreeButton {
    border: 2px solid $black !important;
    border-radius: 6px !important;
    @include mt-xxs;
}

form.loading {
    input,
    select {
        pointer-events: none;
        opacity: 0.5;
    }
}

.payment-method {
    form {
        width: 100%;
        @include mb-xs;
    }

    .btn.btn-payment {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        margin: 0;
        border: 1px solid $line-color;
        @include ph-s;
        @include pv-xs;

        &:hover, &:active {
            background-color: $light-grey;
        }

        .icon:first-of-type {
            margin-left: auto;
        }
    }

    &:last-child {
        .btn.btn-payment {
            border-bottom: 1px solid $line-color;
        }
    }
}