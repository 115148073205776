// DESKTOP
.first-font {
	font-family: $first-font;
}
.second-font {
	font-family: $second-font;
}

// TABLET
.first-font-tablet {
	@include breakpoint (medium down) {
		font-family: $first-font;
	}
}
.second-font-tablet {
	@include breakpoint (medium down) {
		font-family: $second-font;
	}
}

// MOBILE
.first-font-mobile {
	@include breakpoint (small down) {
		font-family: $first-font;
	}
}
.second-font-mobile {
	@include breakpoint (small down) {
		font-family: $second-font;
	}
}