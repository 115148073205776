:root{
    scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: $black;
  list-style-type: none;
  outline: 0 !important;
  @include regular;
  font-family: $first-font;
}

body {
    overflow-x: hidden;
    @include regular;
}

.mobile-none {
    display: none!important;
    padding:0px;
}

.image-border {
    border:1px solid $black;
}

hr {
    border: 0;
    border-top: 1px solid $line-color;
}

.row {
    max-width: 110rem;

    &.centered {
        max-width: 80rem;
    }
}

a {
    text-decoration: none;
}

.border-top-bottom {
    border-top: 1px solid $grey-1;
    border-bottom: 1px solid $grey-1;
}

.upper {
    text-transform: uppercase;
}

.block-scroll {
    overflow-y: hidden;
}

.wrapper {
    min-height: calc(100vh - 40px - 72px - 303px);

    > *:first-child {
        min-height: inherit;
    }
}

.relative {
    position: relative;
}

.width-100 {
    width: 100%;
}
.height-100 {
    height: 100%;
}

.list-unstyled {
    list-style: none;
}

.block {
    display: block;
}
.inline {
    display: inline;
}
.inline-block {
    display: inline-block;
}

.hide {
    display: none;
}

.sticky {
    position: sticky;
}

.clickable {
    cursor: pointer;
}

.sep {
    height: 100%;
    border-right: 1px solid $line-color;
}


.divide {
  border-top: 3px solid $white;
  margin: auto;
  @include mv-xs;
  max-width: 200px;
}

.search-input-fixer {
    border: none !important;
    border-bottom: 1px solid $black !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}

@media screen and (max-width: 639px) {
    .hide-for-small {
        display: none;
    }
}

.dropdown {
    .dropdown-toggle {
        .dropdown-icon {
            transition: transform .4s;
        }

        &.open .dropdown-icon {
            transform: rotate3d(1, 0, 0, 180deg);
        }
    }

    .dropdown-content {
        display: none;
    }
}

.banner-background-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
}

.banner-height {
    height:60vh;
}

.background-image {
    position: relative;
    display: inline-flex;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    
    &.cover {
        background-size: cover;
    }

    .alt {
        display: none;
    }
}

.bottom-mobile {
    @include breakpoint (large up) {
        display: none;
    }

    @include breakpoint (medium down) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: $header-z + 1;
        border-top: 1px solid $grey-1;
        box-shadow: 1px -2px 12px 4px $box-shadow-hover;
    }
}

.border-radius {
    border-radius:$m;
}

.border-top {
    border-top: 1px solid $grey-1;
}

.border-bottom {
    border-bottom: 1px solid $grey-1;
}

.price-container {
    display: inline-flex;
    @include body-2;

    .price {
        margin-right: $xxxs;
        @include semibold;
    }
    .currency {
        @include semibold;
    }

    .price, .currency {
        font: inherit;
        color: inherit;
    }

    &.line-through {
        position: relative;
        text-decoration: none;
        color: $grey-3;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            width: 100%;
            border-bottom: 1px solid $black;
        }
    }
}

.width-50 {
    width:50%;
}

.width-20 {
    width:20%;
}

.modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $overlay-z;

    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($color: $black, $alpha: .7);
        z-index: $overlay-z + 1;
    }

    .content {
        width: 400px;
        background-color: $white;
        z-index: $overlay-z + 2;
    }

    &:not(.open) {
        display: none !important;
    }
}

#country-selector {
    .content {
        padding-bottom: $xxs;

        .title {
            padding: $xs;
        }

        .country-search {
            @include mh-xs;
            
            input {
                padding-left: 0;
                background-color: $white;
                margin: 0;
                padding-left: $xs;
                padding-right: $xl;
                border-radius: 20px;
                box-shadow: 0px 0px 4px 3px $box-shadow;
                background-color: $white;
                @include body-2;
            }
        }

        .country-list {
            @include ph-xs;
            height: 440px;
            overflow-y: auto;

            ul > li {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                left: -$xxs;
                width: calc(100% + #{$xxs} * 2);
                @include pv-xxs;
                @include ph-xxs;

                > span, > a {
                    display: flex;
                    align-items: center;

                    .flag-image {
                        @include mr-xxs;
                    }
                }

                &:not(.separator) {
                    &:hover, &:active {
                        background-color: $light-grey;
                    }
                }

                &.separator {
                    cursor: default;
                    @include mv-xxs;
                    padding: 0;
                    border-bottom: 1px solid $line-color;
                }
            }
        }
    }
}