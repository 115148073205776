header {
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: $header-z;
    transition: background-color .4s;

    #menu-toggle {
        color: $white;
        @include bold;
        background-color: $first-color;
        border-radius: 20px;
        padding: $xxs $xs;
    }

    #mobile-menu-back, #mobile-menu-close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $xl;
        height: $l;
        border-radius: 20px;

        &:active {
            background-color: $grey-1;
        }
    }

    .vertical-hr {
        border-left: 1px solid black;
        height: 20px;
    }
    
    #header-search [type=search] {
        margin: 0;
        padding-left: $xs;
        padding-right: $xl;
        border-radius: 20px;
        box-shadow: 0px 0px 4px 3px $box-shadow;
        background-color: $white;
        @include body-2;

        &::placeholder {
            color: $black;
            @include body-2;
        }

        &:focus {
            box-shadow: 0px 0px 4px 3px $box-shadow-hover;
        }

        ~ .search-btn {
            right: 8px;
        }
    }

    .header-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $xxs $xs;
        border-radius: 20px;
        box-shadow: 0px 0px 4px 3px $box-shadow;
        position: relative;

        &:hover, &:active {
            box-shadow: 0px 0px 4px 3px $box-shadow-hover;
        }

        @include breakpoint (medium down) {
            padding: $xxs ($xxs + $xxxs);
        }
        .cart-number {
            color: $white;
            @include semibold;
            position: absolute;
            top: -5px;
            right: -8px;
            font-size: 11px;
            line-height: 16px;
            background-color: $first-color;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            top: -5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    #profile-icon-container {
        position: relative;

        .dropdown {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            width: max-content;

            ul {
                position: relative;
                left: calc(28px - 50%);
                min-width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: $xxs 0;
                border-radius: 8px;
                background-color: $white;
                box-shadow: 0px 0px 4px 3px $box-shadow;

                li {
                    padding: $xxxs $xs;
                    width: 100%;
                    
                    a, form, [type=submit] {
                        cursor: pointer;
                        @include body-2;
                        text-align: center;
                        display: inline-block;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                    }
    
                    &:hover {
                        background-color: $light-grey;
                    }
                }
            }
        }

        &:hover {
            .dropdown {
                display: block;
            }
        }


    }

    #mobile-search {
        display: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: $overlay-z;
        padding: $xs;
        background-color: rgba($color: $white, $alpha: .96);
    }

    .close-icon {
        position: absolute;
        top: 100px;
        right: 100px;

        @include breakpoint(small down) {
            top: 48px;
            right: 48px;
        }
    }

    .dropdown-column {
        .dropdown {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: $header-z - 1;

            .dropdown-toggle {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                .dropdown-label {
                    @include body-2;
                }

                .dropdown-icon {
                    margin-left: $xs;
                    transition: transform .2s linear;
                }

                &.open {
                    .dropdown-icon {
                        transform: rotate3d(1, 0, 0, 180deg)
                    }
                }
            }

            .dropdown-content {
                display: none;
                position: absolute;
                right: -1px;
                background-color: $white;
                border: 1px solid $line-color;
                width: max-content;
                
                ul {
                    margin:$xxs;
                    
                    li > a {
                        @include body-2;
                        @include pv-xxxs;
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;
                    }
    
                }

            }
        }
    }

}