.product-container {
    border-radius: $m;
    .wishlist-container.icons {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: $menu-z - 1;

        .add-to-wishlist, .remove-from-wishlist {
            display: flex;

            .icon {
                cursor: pointer;
            }
        }
    }

    #images {
        img {
            border: 1px solid $grey-1;
        }
    }

    .product-subtitle {
        white-space:nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}



.list-layout {
    .product-title, .product-subtitle {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}



.product-container:not(.expanded):not(.microbox) {
    background-color: $white;
    padding: $xxs $xxs $xs $xxs;
    border: 1px solid $line-color;

    .product-img {
        picture + picture, img + img {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            z-index: 1;
        }

        &:hover, &:active {
            picture + picture, img + img {
                opacity: 1;
            }
        }
    }
}

.product-container {
    &.expanded {
        .product-prices {
            .percentage-discount {
                border-bottom: 2px solid $first-color;
            }
        }
        thead tr th, tbody tr td {
            padding: $xxs;
            font-size: 14px;
            line-height: 24px;
        }

        img {
            &:not(.icon) {
                border: 1px solid $grey-1;
            }
        }

        .wishlist-container.icons { 
            right: 25px;
        }

        .percentage-discount {
            left: 16px;
        }
    }
}

.size-guide{
    @include breakpoint(medium down){
        overflow-x: auto;
        tr>th:first-child,tr>td:first-child {
            position: sticky;
            left: 0;
        }

        thead>tr>th:first-child , tr:nth-child(even) td{
            background-color: $light-grey;
        }
    
        tr:nth-child(odd) td {
            background: $white;
        }
    }
}


.table-sizes {
    margin: 0;

    &, thead, tbody {
        border: none;
    }

    thead tr th, tbody tr td {
        padding: $xxs;
        background-color: $white;
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
        @include body-2;

        @include breakpoint (medium only) {
            padding: $xxs;
        }

        @include breakpoint (small down) {
            &:first-of-type {
                padding: calc($xxs / 2);
            }
        }
    }

    thead tr th {
        @include body-3;
        @include regular;

        @include breakpoint (medium down) {
            max-width: $xxxl;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }
    }

    tbody tr td {
        @include breakpoint (medium down) {
            @include body-2;
        }
    }

    thead tr, tbody tr {
        border-bottom: 1px solid $line-color;
    }

    tbody tr td:nth-child(2) {
        color: $grey-3;
    }

    tbody tr td .quantity-input-container {
        margin-left: auto;
        margin-right: auto;
    }

    tbody tr td .cart-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border: 1px solid $grey-2;
        border-radius: 4px;

        &:active {
            background-color: $grey-1;
        }
    }
}

.quantity-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $grey-2;
    border-radius: 4px;
    width: max-content;
    height: 32px;

    .btn-plus, .btn-minus {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: $xxs;

        &:active {
            background-color: $grey-1;
        }
    }

    .quantity-input {
        margin: 0;
        padding: 0;
        width: 3rem;
        height: 100%;
        border: none;
        box-shadow: none;
        @include body-2;
        text-align: center;

        @include breakpoint (medium down) {
            width: 2.5rem;
        }

        &::placeholder {
            color: $grey-1;
            @include body-2;
        }
    }

    @include breakpoint (medium down) {
        padding: 0;
    }
}

tbody tr td .cart-input .quantity-input-container {
        margin: 0;
        margin-right: $xxs;

        .btn-cart-remove, .btn-cart-update {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
        }
}

.product-container.grid:not(.expanded) {
    display: grid;
    grid-template-areas:
        'image image image'
        'title title title'
        'subtitle subtitle subtitle'
        'retail-price retail-price sale-price'
        'button button button';

    @include breakpoint (small down) {
        display:block;
        grid-template-areas:
        'image image'
        'title title'
        'subtitle subtitle'
        'retail-price sale-price'
        'button button';
    }

    .image {
        grid-area: image;
        border-bottom: 1px solid $line-color;
        padding-bottom: $xxxs;
        margin-bottom: $xxxs;
        min-width: 0;
    }

    .title {
        grid-area: title;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 0;
    }

    .subtitle {
        grid-area: subtitle;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 0;
    }

    .retail-price {
        grid-area: retail-price;
        justify-content: flex-start;
        align-items: center;
        margin-top: $xxs;
    }

    .sale-price {
        grid-area: sale-price;
        align-items: center;
        min-width: 0;
    }

    .btn {
        grid-area: button;
        min-width: 0;
    }
}