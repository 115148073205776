footer {
	background: $fourth-color;
    border-radius:$m $m 0px 0px;

    .top-row {
        border-top: 1px solid $line-color;
        border-bottom: 1px solid $line-color;

        .dropdown-column {
            border-right: 1px solid $line-color;

            .dropdown {
                position: relative;
                width: 100%;
                height: 100%;
                z-index: $header-z - 1;

                .dropdown-toggle {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    height: 100%;

                    .dropdown-label {
                        @include body-2;
                    }

                    .dropdown-icon {
                        margin-left: $xs;
                        transition: transform .2s linear;
                    }

                    &.open {
                        .dropdown-icon {
                            transform: rotate3d(1, 0, 0, 180deg)
                        }
                    }
                }

                .dropdown-content {
                    display: none;
                    width: calc(100% + 2px);
                    position: absolute;
                    left: -1px;
                    bottom: 100%;
                    background-color: $white;
                    border: 1px solid $line-color;
                    border-bottom: none;

                    li > a {
                        @include body-2;
                        @include pv-xxxs;
                        display: block;
                        width: 100%;
                    }
                }
            }
        }

        @include breakpoint (medium down) {
            .dropdown-toggle {
                text-transform: uppercase;
                @include pv-xs;
            }

            #language-dropdown .dropdown-toggle {
                justify-content: end;
            }
        }
    }

    .footer-column {
        @include breakpoint (large up) {
            .title {
                pointer-events: none;
            }

            ul {
                display: block !important;
                height: 100% !important;
            }
        }

        @include breakpoint (medium down) {
            border-bottom: 1px solid $line-color;

            .title {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transition: transform .4s ease-in-out;
                    @include pseudo-element-image($icons + '/general-icons/arrow-down-black.svg', 9px, 5px);
                }

                &.open::after {
                    transform: rotate3d(1, 0, 0, 180deg);
                }
            }
        }
    }
}
