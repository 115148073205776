@mixin pseudo-element-image($src, $width, $height) {
    background-image: url($src);
    width: $width;
    height: $height;
    background-size: contain;
    background-repeat: no-repeat;
}

@mixin children-inherit-font {
    a,
    p,
    span {
        font: inherit;
        color: inherit;
    }
}

@mixin basic-transition {
  transition: all 0.15s;
}

// *** DO NOT REMOVE THIS ***
//$$ mixins-hook-start $$

// typography
@mixin h1 {
	font-size: 70px;
	font-weight: 700;
	line-height: 88px;
	margin: 0;
}
@mixin h1-tablet {
	@include breakpoint (medium down) {
		font-size: 70px;
		font-weight: 700;
		line-height: 88px;
		margin: 0;
	}
}
@mixin h1-mobile {
	@include breakpoint (small down) {
		font-size: 70px;
		font-weight: 700;
		line-height: 88px;
		margin: 0;
	}
}
@mixin h2 {
	font-size: 35px;
	font-weight: 400;
	line-height: 40px;
}
@mixin h2-tablet {
	@include breakpoint (medium down) {
		font-size: 35px;
		font-weight: 400;
		line-height: 40px;
	}
}
@mixin h2-mobile {
	@include breakpoint (small down) {
		font-size: 35px;
		font-weight: 400;
		line-height: 40px;
	}
}
@mixin h3 {
	font-size: 25px;
	font-weight: 400;
	line-height: 32px;
}
@mixin h3-tablet {
	@include breakpoint (medium down) {
		font-size: 25px;
		font-weight: 400;
		line-height: 32px;
	}
}
@mixin h3-mobile {
	@include breakpoint (small down) {
		font-size: 25px;
		font-weight: 400;
		line-height: 32px;
	}
}
@mixin h4 {
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
}
@mixin h4-tablet {
	@include breakpoint (medium down) {
		font-size: 18px;
		font-weight: 400;
		line-height: 24px;
	}
}
@mixin h4-mobile {
	@include breakpoint (small down) {
		font-size: 18px;
		font-weight: 400;
		line-height: 24px;
	}
}
@mixin body-1 {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}
@mixin body-1-tablet {
	@include breakpoint (medium down) {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}
}
@mixin body-1-mobile {
	@include breakpoint (small down) {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}
}
@mixin body-2 {
	font-size: 14px;
	line-height: 24px;
}
@mixin body-2-tablet {
	@include breakpoint (medium down) {
		font-size: 14px;
		line-height: 24px;
	}
}
@mixin body-2-mobile {
	@include breakpoint (small down) {
		font-size: 14px;
		line-height: 24px;
	}
}
@mixin body-3 {
	font-size: 12px;
	line-height: 16px;
}
@mixin body-3-tablet {
	@include breakpoint (medium down) {
		font-size: 12px;
		line-height: 16px;
	}
}
@mixin body-3-mobile {
	@include breakpoint (small down) {
		font-size: 12px;
		line-height: 16px;
	}
}

// borders
@mixin border {
	border: 1px solid $line-color;
}
@mixin border-tablet {
	@include breakpoint (medium down) {
		border: 1px solid $line-color;
	}
}
@mixin border-mobile {
	@include breakpoint (small down) {
		border: 1px solid $line-color;
	}
}
@mixin border-no-top {
	border-top: none;
}
@mixin border-no-top-tablet {
	@include breakpoint (medium down) {
		border-top: none;
	}
}
@mixin border-no-top-mobile {
	@include breakpoint (small down) {
		border-top: none;
	}
}
@mixin border-no-bottom {
	border-bottom: none;
}
@mixin border-no-bottom-tablet {
	@include breakpoint (medium down) {
		border-bottom: none;
	}
}
@mixin border-no-bottom-mobile {
	@include breakpoint (small down) {
		border-bottom: none;
	}
}
@mixin border-no-left {
	border-left: none;
}
@mixin border-no-left-tablet {
	@include breakpoint (medium down) {
		border-left: none;
	}
}
@mixin border-no-left-mobile {
	@include breakpoint (small down) {
		border-left: none;
	}
}
@mixin border-no-right {
	border-right: none;
}
@mixin border-no-right-tablet {
	@include breakpoint (medium down) {
		border-right: none;
	}
}
@mixin border-no-right-mobile {
	@include breakpoint (small down) {
		border-right: none;
	}
}
@mixin border-black {
	border-color: $black;
}
@mixin border-black-tablet {
	@include breakpoint (medium down) {
		border-color: $black;
	}
}
@mixin border-black-mobile {
	@include breakpoint (small down) {
		border-color: $black;
	}
}
@mixin border-grey-3 {
	border-color: $grey-3;
}
@mixin border-grey-3-tablet {
	@include breakpoint (medium down) {
		border-color: $grey-3;
	}
}
@mixin border-grey-3-mobile {
	@include breakpoint (small down) {
		border-color: $grey-3;
	}
}
@mixin border-grey-2 {
	border-color: $grey-2;
}
@mixin border-grey-2-tablet {
	@include breakpoint (medium down) {
		border-color: $grey-2;
	}
}
@mixin border-grey-2-mobile {
	@include breakpoint (small down) {
		border-color: $grey-2;
	}
}
@mixin border-grey-1 {
	border-color: $grey-1;
}
@mixin border-grey-1-tablet {
	@include breakpoint (medium down) {
		border-color: $grey-1;
	}
}
@mixin border-grey-1-mobile {
	@include breakpoint (small down) {
		border-color: $grey-1;
	}
}
@mixin border-light-grey {
	border-color: $light-grey;
}
@mixin border-light-grey-tablet {
	@include breakpoint (medium down) {
		border-color: $light-grey;
	}
}
@mixin border-light-grey-mobile {
	@include breakpoint (small down) {
		border-color: $light-grey;
	}
}
@mixin border-white {
	border-color: $white;
}
@mixin border-white-tablet {
	@include breakpoint (medium down) {
		border-color: $white;
	}
}
@mixin border-white-mobile {
	@include breakpoint (small down) {
		border-color: $white;
	}
}
@mixin border-first-color {
	border-color: $first-color;
}
@mixin border-first-color-tablet {
	@include breakpoint (medium down) {
		border-color: $first-color;
	}
}
@mixin border-first-color-mobile {
	@include breakpoint (small down) {
		border-color: $first-color;
	}
}
@mixin border-second-color {
	border-color: $second-color;
}
@mixin border-second-color-tablet {
	@include breakpoint (medium down) {
		border-color: $second-color;
	}
}
@mixin border-second-color-mobile {
	@include breakpoint (small down) {
		border-color: $second-color;
	}
}
@mixin border-third-color {
	border-color: $third-color;
}
@mixin border-third-color-tablet {
	@include breakpoint (medium down) {
		border-color: $third-color;
	}
}
@mixin border-third-color-mobile {
	@include breakpoint (small down) {
		border-color: $third-color;
	}
}
@mixin radius-small {
	border-radius: $radius-small;
}
@mixin radius-small-tablet {
	@include breakpoint (medium down) {
		border-radius: $radius-small;
	}
}
@mixin radius-small-mobile {
	@include breakpoint (small down) {
		border-radius: $radius-small;
	}
}
@mixin radius-medium {
	border-radius: $radius-medium;
}
@mixin radius-medium-tablet {
	@include breakpoint (medium down) {
		border-radius: $radius-medium;
	}
}
@mixin radius-medium-mobile {
	@include breakpoint (small down) {
		border-radius: $radius-medium;
	}
}
@mixin radius-large {
	border-radius: $radius-large;
}
@mixin radius-large-tablet {
	@include breakpoint (medium down) {
		border-radius: $radius-large;
	}
}
@mixin radius-large-mobile {
	@include breakpoint (small down) {
		border-radius: $radius-large;
	}
}

// text-alignment
@mixin txt-center {
	text-align: center;
}
@mixin txt-center-tablet {
	@include breakpoint (medium down) {
		text-align: center;
	}
}
@mixin txt-center-mobile {
	@include breakpoint (small down) {
		text-align: center;
	}
}
@mixin txt-left {
	text-align: left;
}
@mixin txt-left-tablet {
	@include breakpoint (medium down) {
		text-align: left;
	}
}
@mixin txt-left-mobile {
	@include breakpoint (small down) {
		text-align: left;
	}
}
@mixin txt-right {
	text-align: right;
}
@mixin txt-right-tablet {
	@include breakpoint (medium down) {
		text-align: right;
	}
}
@mixin txt-right-mobile {
	@include breakpoint (small down) {
		text-align: right;
	}
}

// text-decorations
@mixin underline {
	text-decoration: underline;
}
@mixin underline-tablet {
	@include breakpoint (medium down) {
		text-decoration: underline;
	}
}
@mixin underline-mobile {
	@include breakpoint (small down) {
		text-decoration: underline;
	}
}
@mixin line-through {
	text-decoration: line-through;
}
@mixin line-through-tablet {
	@include breakpoint (medium down) {
		text-decoration: line-through;
	}
}
@mixin line-through-mobile {
	@include breakpoint (small down) {
		text-decoration: line-through;
	}
}

// text-transforms
@mixin lower {
	text-transform: lowercase;
}
@mixin lower-tablet {
	@include breakpoint (medium down) {
		text-transform: lowercase;
	}
}
@mixin lower-mobile {
	@include breakpoint (small down) {
		text-transform: lowercase;
	}
}
@mixin upper {
	text-transform: uppercase;
}
@mixin upper-tablet {
	@include breakpoint (medium down) {
		text-transform: uppercase;
	}
}
@mixin upper-mobile {
	@include breakpoint (small down) {
		text-transform: uppercase;
	}
}
@mixin capitalize {
	text-transform: capitalize;
}
@mixin capitalize-tablet {
	@include breakpoint (medium down) {
		text-transform: capitalize;
	}
}
@mixin capitalize-mobile {
	@include breakpoint (small down) {
		text-transform: capitalize;
	}
}

// font-weights
@mixin regular {
	font-weight: 400;
}
@mixin regular-tablet {
	@include breakpoint (medium down) {
		font-weight: 400;
	}
}
@mixin regular-mobile {
	@include breakpoint (small down) {
		font-weight: 400;
	}
}
@mixin semibold {
	font-weight: 600;
}
@mixin semibold-tablet {
	@include breakpoint (medium down) {
		font-weight: 600;
	}
}
@mixin semibold-mobile {
	@include breakpoint (small down) {
		font-weight: 600;
	}
}
@mixin bold {
	font-weight: 700;
}
@mixin bold-tablet {
	@include breakpoint (medium down) {
		font-weight: 700;
	}
}
@mixin bold-mobile {
	@include breakpoint (small down) {
		font-weight: 700;
	}
}

// fonts
@mixin first-font {
	font-family: $first-font;
}
@mixin first-font-tablet {
	@include breakpoint (medium down) {
		font-family: $first-font;
	}
}
@mixin first-font-mobile {
	@include breakpoint (small down) {
		font-family: $first-font;
	}
}
@mixin second-font {
	font-family: $second-font;
}
@mixin second-font-tablet {
	@include breakpoint (medium down) {
		font-family: $second-font;
	}
}
@mixin second-font-mobile {
	@include breakpoint (small down) {
		font-family: $second-font;
	}
}

//$$ mixins-hook-end $$
// *** DO NOT REMOVE THIS ***