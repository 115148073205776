// DESKTOP
.lower {
	text-transform: lowercase;
}
.upper {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}

// TABLET
.lower-tablet {
	@include breakpoint (medium down) {
		text-transform: lowercase;
	}
}
.upper-tablet {
	@include breakpoint (medium down) {
		text-transform: uppercase;
	}
}
.capitalize-tablet {
	@include breakpoint (medium down) {
		text-transform: capitalize;
	}
}

// MOBILE
.lower-mobile {
	@include breakpoint (small down) {
		text-transform: lowercase;
	}
}
.upper-mobile {
	@include breakpoint (small down) {
		text-transform: uppercase;
	}
}
.capitalize-mobile {
	@include breakpoint (small down) {
		text-transform: capitalize;
	}
}