.btn {
    cursor: pointer;
    border: none;
    height: 40px;
    padding: $s $xs;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include semibold;
    @include body-1;
    text-transform: uppercase;
    white-space: nowrap;
    border-radius:$m;
    @include basic-transition;


    @include breakpoint (medium down) {
        @include body-2;
    }

    span, p {
        color: inherit;
        font-size: inherit;
    }

    &.btn-primary {
        color: $white;
        background-color: $black;

        &:not(:disabled):hover {
            background-color: hover-color($black);
        }

        /* IMPORTANT: with ::after input type="button|submit" with class .btn-primary are no longer allowed (inputs cannot have child elements) */
        &::after {
            content: '';
            display: inline-block;
            margin-left: $xxs;
            @include pseudo-element-image($icons + '/theme-icons/arrow-right-shadow-white.svg', 18px, 18px);
        }
    }

    &.btn-primary-first-color {
        color: $white;
        background-color: $first-color;

        &:not(:disabled):hover {
            background-color: hover-color($first-color);
        }
    }

    &.btn-secondary {
        color: $black;
        background-color: $white;

        &:not(:disabled):hover {
            background-color: $light-grey;
        }

        /* IMPORTANT: with ::after input type="button|submit" with class .btn-secondary are no longer allowed (inputs cannot have child elements) */
        &::after {
            content: '';
            display: inline-block;
            margin-left: $xxs;
            @include pseudo-element-image($icons + '/theme-icons/arrow-right-shadow-pink.svg', 18px, 18px);
        }
    }

    /* btn-primary w/o arrows */
    &.btn-third {
        color: $white;
        background-color: $black;

        &:not(:disabled):hover {
            background-color: hover-color($black);
        }
    }

    &.btn-fourth {
        color: $black;
        background-color: transparent;
        border: 1px solid $black;

        &:not(:disabled):hover {
            color: hover-color($black);
            border-color: hover-color($black);
        }
    }

    &.btn-confirmed {
        @include breakpoint (medium down) {
            height: 56px;
            @include body-1;
            @include semibold;
        }
    }

    &.btn-no-padding {
        padding: 0 !important;
    }

    &.btn-no-margin {
        margin: 0 !important;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }

    &.btn-padding {
        padding: $xxs $xs;
    }
}

.border-paypal.button {
    border: 1px solid $second-color;
    border-radius: $radius-small;
}

:after {
    box-sizing: border-box;
}

.label-input {
    position: relative;
    margin-top: $xs;

    label {
        text-align: left;
        font-size: 14px;
        line-height: 24px;
        color: $black;
        @include semibold;

        span {
            @include regular;
        }
        a {
            @include semibold;
        }
    }

    input {
        border: 1px solid $black;
        box-shadow: none;
        border-radius: 0;
        margin: initial;
        padding: .5rem;
        @include body-2;

        &:focus {
            border-color: $second-color;
            box-shadow: none;

            + label:not(.error) {
                color: $second-color;
            }
        }
    }

    input::placeholder {
        color: $grey-3;
        opacity: 1;
        @include body-3;
    }

    textarea,
    select {
        border: none;
        box-shadow: none;
        border: 1px solid $black;
        border-radius: 0;
        margin: initial;
        @include body-3;

        &:focus {
            border-color: $second-color;
            box-shadow: none;
        }
    }

    input, select, textarea {
        &:disabled, &:disabled + label {
            cursor: not-allowed;
            opacity: .3;
        }
    }

    select:disabled[readonly] {
        cursor: not-allowed;
        background-color: $light-grey;
        opacity: 1;
    }

    input, select, textarea {
        &.error {
            margin-bottom: 0;
        }

        &.error:not(:focus) {
            color: $danger;
            border-color: $danger;
        }

        &.error:not(:focus)::placeholder {
            color: rgba($color: $danger, $alpha: .7);
        }
    }

    .iti {
        margin: initial;

        .iti__selected-dial-code {
            @include body-3;
        }
    }
}

.checkbox-container {
    display: flex;
    align-items: flex-start;
    position: relative;

    label a {
        @include bold;
    }

    .checkbox {
        display: none;
    }

    .checkbox + label {
        position: relative;
        margin: 0;
        margin-left: $checkbox-size + $checkbox-offset-x;
    }

    .checkbox + label::before {
        content: '';
        position: absolute;
        top: $checkbox-offset-y;
        left: -($checkbox-size + $checkbox-offset-x);
        width: $checkbox-size;
        height: $checkbox-size;
        border: $checkbox-border-width solid $checkbox-border-color;
        border-radius: $checkbox-border-radius;
    }

    @mixin fullMode {
        content: '';
        position: absolute;
        top: calc(#{$checkbox-offset-y} + #{2 * $checkbox-full-margin});
        left: -($checkbox-size + $checkbox-offset-x) + ($checkbox-full-margin + $checkbox-border-width);
        width: $checkbox-size - (2 * ($checkbox-full-margin + $checkbox-border-width));
        height: $checkbox-size - (2 * ($checkbox-full-margin + $checkbox-border-width));
        background-color: $checkbox-checked-color;
        border-radius: $checkbox-border-radius;
    }

    @mixin tickMode {
        content: '';
        position: absolute;
        top: $checkbox-tick-offset-y;
        left: -($checkbox-size + $checkbox-offset-x) + ($checkbox-full-margin + $checkbox-border-width + $checkbox-tick-offset-x);
        background-color: transparent !important;
        border: $checkbox-tick-width solid $checkbox-checked-color;
        border-top: none;
        border-right: none;
        height: $checkbox-tick-limb-left-length;
        width: $checkbox-tick-limb-right-length;
        transform: rotate3d(0, 0, 1, -45deg);
    }

    @if $checkbox-default-mode == 'full' {
        &:not(.tick) .checkbox:checked + label::after {
            @include fullMode;
        }

        &.tick .checkbox:checked + label::after {
            @include tickMode;
        }
    }
    @else {
        &:not(.full) .checkbox:checked + label::after {
            @include tickMode;
        }

        &.full .checkbox:checked + label::after {
            @include fullMode;
        }
    }

    .checkbox.error + label {
        &, * {
            color: $danger;
        }

        &::before {
            border-color: $danger;
        }
    }

    .checkbox:disabled + label {
        cursor: not-allowed;
        opacity: .3;
    }

}

.checkbox-container {
    &.first-color {
        .checkbox + label::before {
            border-color: $first-color;
        }
    }

    &.second-color {
        .checkbox + label::before {
            border-color: $second-color;
        }

        .checkbox:checked + label::after {
            border-color: $second-color;
            background-color: $second-color;
        }
    }
}

.radio-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    .radio {
        display: none;
    }

    .radio + label {
        margin-left: $radio-size + $radio-offset-x;
    }

    .radio + label::before {
        content: '';
        position: absolute;
        top: $radio-offset-y;
        left: 0;
        width: $radio-size;
        height: $radio-size;
        border: $radio-border-width solid $radio-border-color;
        border-radius: 50%;
    }

    .radio:checked + label::after {
        content: '';
        position: absolute;
        top: $radio-offset-y + $radio-border-width + $radio-selected-margin;
        left: $radio-border-width + $radio-selected-margin;
        width: $radio-size - 2 * ($radio-border-width + $radio-selected-margin);
        height: $radio-size - 2 * ($radio-border-width + $radio-selected-margin);
        background-color: $radio-selected-color;
        border-radius: 50%;
    }

    .radio.error + label {
        &, * {
            color: $danger;
        }

        &::before {
            border-color: $danger;
        }
    }

    .radio:disabled + label {
        cursor: not-allowed;
        opacity: .3;
    }
}

.radio-container {
    &.first-color {
        .radio:checked + label::after {
            background-color: $first-color;
        }
    }

    &.second-color {
        .radio:checked + label::after {
            background-color: $second-color;
        }
    }
}

.search-container {
    position: relative;
    height: 80%;
    @include breakpoint(medium down) {
        height: fit-content;
        width: 80%;
        border-bottom: 3px solid $first-color;
    }

    .search-input {
        height: 40px;
        width: 100%;
        border: none;
        background-color: $light-grey;
        box-shadow: none;
        border-radius: 4px;
        padding-left: $s;
        padding-right: $l;
        @include breakpoint(medium down) {
            background-color: transparent;
            font-size: 22px;
            height: 24px;
        }
		&::placeholder {
			color: $grey-3;
			opacity: 1;
		}

		&::-webkit-search-cancel-button {
			-webkit-appearance: none;
		}
	}


    .search-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        @include breakpoint(medium down) {
            width: 20px;
            height: 20px;
        }
    }

    .search-cancel {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        visibility: hidden;
    }

    &.has-cancel-btn .search-input:not(:placeholder-shown) {
        ~ .search-btn {
            display: none;
        }
        ~ .search-cancel {
            visibility: visible;
        }
    }
}

label.error {
    &, * {
        color: $danger;
    }
}