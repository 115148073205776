// DESKTOP
.border {
	border: 1px solid $line-color;
}
.border-no-top {
	border-top: none;
}
.border-no-bottom {
	border-bottom: none;
}
.border-no-left {
	border-left: none;
}
.border-no-right {
	border-right: none;
}
.border-black {
	border-color: $black;
}
.border-grey-3 {
	border-color: $grey-3;
}
.border-grey-2 {
	border-color: $grey-2;
}
.border-grey-1 {
	border-color: $grey-1;
}
.border-light-grey {
	border-color: $light-grey;
}
.border-white {
	border-color: $white;
}
.border-first-color {
	border-color: $first-color;
}
.border-second-color {
	border-color: $second-color;
}
.border-third-color {
	border-color: $third-color;
}
.radius-small {
	border-radius: $radius-small;
}
.radius-medium {
	border-radius: $radius-medium;
}
.radius-large {
	border-radius: $radius-large;
}

// TABLET
.border-tablet {
	@include breakpoint (medium down) {
		border: 1px solid $line-color;
	}
}
.border-no-top-tablet {
	@include breakpoint (medium down) {
		border-top: none;
	}
}
.border-no-bottom-tablet {
	@include breakpoint (medium down) {
		border-bottom: none;
	}
}
.border-no-left-tablet {
	@include breakpoint (medium down) {
		border-left: none;
	}
}
.border-no-right-tablet {
	@include breakpoint (medium down) {
		border-right: none;
	}
}
.border-black-tablet {
	@include breakpoint (medium down) {
		border-color: $black;
	}
}
.border-grey-3-tablet {
	@include breakpoint (medium down) {
		border-color: $grey-3;
	}
}
.border-grey-2-tablet {
	@include breakpoint (medium down) {
		border-color: $grey-2;
	}
}
.border-grey-1-tablet {
	@include breakpoint (medium down) {
		border-color: $grey-1;
	}
}
.border-light-grey-tablet {
	@include breakpoint (medium down) {
		border-color: $light-grey;
	}
}
.border-white-tablet {
	@include breakpoint (medium down) {
		border-color: $white;
	}
}
.border-first-color-tablet {
	@include breakpoint (medium down) {
		border-color: $first-color;
	}
}
.border-second-color-tablet {
	@include breakpoint (medium down) {
		border-color: $second-color;
	}
}
.border-third-color-tablet {
	@include breakpoint (medium down) {
		border-color: $third-color;
	}
}
.radius-small-tablet {
	@include breakpoint (medium down) {
		border-radius: $radius-small;
	}
}
.radius-medium-tablet {
	@include breakpoint (medium down) {
		border-radius: $radius-medium;
	}
}
.radius-large-tablet {
	@include breakpoint (medium down) {
		border-radius: $radius-large;
	}
}

// MOBILE
.border-mobile {
	@include breakpoint (small down) {
		border: 1px solid $line-color;
	}
}
.border-no-top-mobile {
	@include breakpoint (small down) {
		border-top: none;
	}
}
.border-no-bottom-mobile {
	@include breakpoint (small down) {
		border-bottom: none;
	}
}
.border-no-left-mobile {
	@include breakpoint (small down) {
		border-left: none;
	}
}
.border-no-right-mobile {
	@include breakpoint (small down) {
		border-right: none;
	}
}
.border-black-mobile {
	@include breakpoint (small down) {
		border-color: $black;
	}
}
.border-grey-3-mobile {
	@include breakpoint (small down) {
		border-color: $grey-3;
	}
}
.border-grey-2-mobile {
	@include breakpoint (small down) {
		border-color: $grey-2;
	}
}
.border-grey-1-mobile {
	@include breakpoint (small down) {
		border-color: $grey-1;
	}
}
.border-light-grey-mobile {
	@include breakpoint (small down) {
		border-color: $light-grey;
	}
}
.border-white-mobile {
	@include breakpoint (small down) {
		border-color: $white;
	}
}
.border-first-color-mobile {
	@include breakpoint (small down) {
		border-color: $first-color;
	}
}
.border-second-color-mobile {
	@include breakpoint (small down) {
		border-color: $second-color;
	}
}
.border-third-color-mobile {
	@include breakpoint (small down) {
		border-color: $third-color;
	}
}
.radius-small-mobile {
	@include breakpoint (small down) {
		border-radius: $radius-small;
	}
}
.radius-medium-mobile {
	@include breakpoint (small down) {
		border-radius: $radius-medium;
	}
}
.radius-large-mobile {
	@include breakpoint (small down) {
		border-radius: $radius-large;
	}
}