.cart-summary {
    background-color: $white;
    padding: $s;

    @include breakpoint(small down) {
        padding: $xs
    }

    #country_id {
        border-radius: 0;
        @include body-3;
    }

    #burn-coupon-dropdown {
        border-top: 1px solid $line-color;
        border-bottom: 1px solid $line-color;

        .dropdown-toggle {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include pv-xs;
            @include bold;
            .dropdown-label {
                @include bold;
            }
        }
    }

    .cart-detail-row {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        @include body-2;
    }

    label {
        display: flex;
    }
}