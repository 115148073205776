// DESKTOP
.txt-center {
	text-align: center;
}
.txt-left {
	text-align: left;
}
.txt-right {
	text-align: right;
}

// TABLET
.txt-center-tablet {
	@include breakpoint (medium down) {
		text-align: center;
	}
}
.txt-left-tablet {
	@include breakpoint (medium down) {
		text-align: left;
	}
}
.txt-right-tablet {
	@include breakpoint (medium down) {
		text-align: right;
	}
}

// MOBILE
.txt-center-mobile {
	@include breakpoint (small down) {
		text-align: center;
	}
}
.txt-left-mobile {
	@include breakpoint (small down) {
		text-align: left;
	}
}
.txt-right-mobile {
	@include breakpoint (small down) {
		text-align: right;
	}
}